import React, { useContext, useState, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import SimTypeChip from "utils/SimTypeChip";
import { LoadersContext } from "_models/context/app";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import { renderPrevencionCell } from "utils/colorHelper";

const theme = createTheme({
    palette: {
        primary: {
            main: red[400],
        },
        secondary: {
            main: orange[700],
        },
        text: {
            default: "#000000",
        },
    },
});

export const DataGridHook = (props) => {
    const loadersContext = useContext(LoadersContext);

    const {
        getValues,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const { setIsScreenLoading } = loadersContext || {};

    const {
        getSaleOrdersAction,
        saleOrders,
        cleanSaleOrdersAction,
        getSaleOrderDetailAction,
        saleOrderDetail,
        cleanSaleOrderDetailAction,
    } = props || {};

    const { payload, state, status, loaded, method } = saleOrders || {};

    const { result } = payload || {};

    const changePage = (newPage) => {
        setDatagridPage(newPage);
    };
    const changePageSize = (newPageSize) => {
        setDatagridPageSize(newPageSize);
        setDatagridPage(0);
    };

    const [dateValue, setDateValue] = useState(null);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [datagridPageSize, setDatagridPageSize] = useState(50);
    const [rowCount, setRowCount] = useState(50);
    const [datagridPage, setDatagridPage] = useState(0);
    const [loadingDatagrid, setLoadingDatagrid] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalOpenDetailOrder, setModalOpenDetailOrder] = useState(false);

    const [frontErrorMessage, setFrontErrorMessage] = useState("");

    useEffect(() => {
        generateDataColumns();

        return () => {
            cleanSaleOrdersAction();
            setIsScreenLoading(false);
        };
    }, []);

    useEffect(() => {
        if (dateValue) {
            const formatDateValue = moment(
                dateValue.format("YYYY-MM-DD")
            ).format();
        }
    }, [dateValue]);

    useEffect(() => {
        if (
            saleOrders?.state === "error" &&
            (saleOrders?.status === null || saleOrders?.status >= 300)
        ) {
            setOpen(true);
        } else {
            setOpen(false);
        }
        setLoadingDatagrid(false);
    }, [saleOrders]);

    useEffect(() => {
        generateDataRows();
    }, [result]);

    useEffect(() => {
        const { state, status } = saleOrderDetail || {};

        if (state === "success" && (status >= 200 || status < 300)) {
            setOpen(false);
            setModalOpenDetailOrder(true);
        } else if (state === "error" && (status >= 300 || status === null)) {
            setOpen(true);
        } else {
            setOpen(false);
        }

        setIsScreenLoading(false);
    }, [saleOrderDetail]);

    const handleActionOrderDetail = (props) => {
        getSaleOrderDetailAction(props);
        setIsScreenLoading(true);
    };

    const generateDataColumns = () => {
        let leadsColumns = [
            {
                field: "prevencion",
                headerName: "Prevención",
                width: 250,
                renderCell: (params) => {
                    return renderPrevencionCell(params.row);
                },
            },
            {
                field: "id",
                headerName: "Número de orden",
                width: 200,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { id, order_id, bg, color } = row || {};
                    return (
                        <div style={{ backgroundColor: bg }}>
                            <LoadingButton
                                fullWidth
                                size="small"
                                onClick={() =>
                                    handleActionOrderDetail(order_id)
                                }
                                sx={{
                                    fontSize: "17px",
                                    color: color || "#624591",
                                }}
                            >
                                {id}
                            </LoadingButton>
                        </div>
                    );
                },
            },
            {
                field: "sim_type",
                headerName: "Tipo de SIM",
                width: 310,
                renderCell: (params) => {
                    if (!params.value) {
                        return null;
                    }
                    return <SimTypeChip simTypeName={params.value} />;
                },
            },
            {
                field: "date",
                headerName: "Fecha de compra",
                width: 310,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { date, bg, color } = row || {};
                    return (
                        <div style={{ backgroundColor: bg, color: color }}>
                            {date}
                        </div>
                    );
                },
            },
            {
                field: "name_order",
                headerName: "Nombre",
                width: 310,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { name_order, bg, color } = row || {};
                    return (
                        <div style={{ backgroundColor: bg, color: color }}>
                            {name_order}
                        </div>
                    );
                },
            },
            {
                field: "contact_number_order",
                headerName: "Teléfono",
                width: 310,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { contact_number_order, bg, color } = row || {};
                    return (
                        <div style={{ backgroundColor: bg, color: color }}>
                            {contact_number_order}
                        </div>
                    );
                },
            },
            {
                field: "email_order",
                headerName: "Correo electrónico",
                width: 310,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { email_order, bg, color } = row || {};
                    return (
                        <div style={{ backgroundColor: bg, color: color }}>
                            {email_order}
                        </div>
                    );
                },
            },
        ];

        setColumns(leadsColumns);
    };

    const generateDataRows = () => {
        let ordersRow = [];

        if (result) {
            result.map((element, index) => {
                let bg = "#fff";
                let color = "#000";

                if (element.is_fraud && element.is_chargeback) {
                    bg = theme.palette.primary.main;
                    color = "#FFF";
                } else if (element.is_fraud) {
                    bg = theme.palette.primary.main;
                    color = "#FFF";
                } else if (element.is_chargeback) {
                    bg = theme.palette.secondary.main;
                    color = "#FFF";
                } else {
                    bg = theme.palette.background.default;
                }

                ordersRow.push({
                    id: element?.order_number,
                    date: (element?.created).split("T")[0],
                    name_order: element?.name,
                    contact_number_order: element?.contact_phone,
                    email_order: element?.email,
                    order_id: element?.order_id,
                    sim_type: element?.sim_type,
                    bg: bg,
                    color: color,
                    is_chargeback: element?.is_chargeback,
                    is_fraud: element?.is_fraud,
                });
            });
        }

        setRows(ordersRow);
    };

    const inputChange = (e) => {};

    //
    const filtersValues = getValues();
    const { numberOrder } = filtersValues || {};
    //

    useEffect(() => {
        if (!open) {
            setFrontErrorMessage();
        }
    }, [open]);

    const getSaleOrdersMethod = (from) => {
        setLoadingDatagrid(true);

        const filtersValues = getValues();

        const { numberOrder, nameOrder, phoneNumberOrder, emailOrder } =
            filtersValues || {};

        if (
            numberOrder === "" &&
            nameOrder === "" &&
            phoneNumberOrder === "" &&
            emailOrder === "" &&
            (dateValue === "" || dateValue === null || dateValue === undefined)
        ) {
            setFrontErrorMessage(<p>"Debe ingresar al menos un filtro."</p>);
            setLoadingDatagrid(false);
            setOpen(true);
        } else {
            let formatDateValue = "";

            if (dateValue) {
                formatDateValue = moment(
                    dateValue.format("YYYY-MM-DD")
                ).format();
            }

            let dateFormatted = "";
            if (formatDateValue.split("T")[0]) {
                dateFormatted = formatDateValue.split("T")[0];
            }

            if (from === "search") {
                setDatagridPage(0);
            }
            getSaleOrdersAction({
                page: from === "search" ? 0 : datagridPage,
                size: datagridPageSize,
                number_order: numberOrder,
                name_order: nameOrder,
                phone_number_order: phoneNumberOrder,
                email_order: emailOrder,
                date_order: dateFormatted,
            });
        }
    };

    const onSubmit = (e) => {
        getSaleOrdersMethod("search");
    };

    const registerOptions = {
        numberOrder: {
            // required: "Número de orden ",
            minLength: {
                value: 3,
                message: "Mínimo 3 caracteres",
            },
            maxLength: {
                value: 150,
                message: "Máximo 150 caracteres",
            },
        },
        nameOrder: {
            // required: "Nombre es requerido",
            minLength: {
                value: 3,
                message: "Mínimo 3 caracteres",
            },
            maxLength: {
                value: 150,
                message: "Máximo 150 caracteres",
            },
        },

        phoneNumberOrder: {
            // required: "Número telefónico es requerido",
            pattern: {
                value: /^(0|[0-9]\d*)(\.\d+)?$/,
                message: "El formato telefónico no es correcto",
            },
        },

        emailOrder: {
            // required: "El correo electrónico es requerido",
            pattern: {
                value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/,
                message:
                    "El valor ingresado no coincide con el formato del correo electrónico",
            },
        },
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };
    const handleClose = () => {
        setOpen(false);
        setIsScreenLoading(false);
    };

    const PopModalError = () => {
        return (
            <div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Error
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {"---"}
                            {frontErrorMessage}
                            {saleOrders?.payload?.code ||
                            saleOrders?.payload?.response?.data?.detail ||
                            saleOrderDetail?.payload?.response?.data?.detail ||
                            saleOrderDetail?.payload?.code ? (
                                <>
                                    <p>
                                        {
                                            saleOrders?.payload?.response?.data
                                                ?.detail
                                        }
                                    </p>
                                    <p>
                                        {saleOrders?.payload?.message
                                            ? "[" +
                                              saleOrders?.payload?.message +
                                              "]"
                                            : ""}
                                    </p>
                                    <p>
                                        {
                                            saleOrderDetail?.payload?.response
                                                ?.data?.detail
                                        }
                                    </p>
                                    <p>
                                        {saleOrderDetail?.payload?.message
                                            ? "[" +
                                              saleOrderDetail?.payload
                                                  ?.message +
                                              "]"
                                            : ""}
                                    </p>
                                </>
                            ) : null}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        );
    };

    return {
        columns,
        rows,
        datagridPageSize,
        rowCount,
        datagridPage,
        loadingDatagrid,
        modalOpenDetailOrder,
        inputChange,
        onSubmit,
        registerOptions,
        PopModalError,
        handleSubmit,
        dateValue,
        setDateValue,
        register,
        errors,
        changePage,
        changePageSize,
        setModalOpenDetailOrder,
    };
};
