import axiosTokenInstance from "services/api/backoffice";
import {
    DETAILS_TRANSACTIONS_TOKU,
    DETAILS_TRANSACTIONS_TOKU_ERROR,
} from "_models/redux/types";

// Action function
export const detailTransactionTokuAction =
    (transactions_id) => async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.get(
                `/api/reports/transactions-details-toku/${transactions_id}`
            );

            const { data, status } = response || {};

            dispatch({
                type: DETAILS_TRANSACTIONS_TOKU,
                response: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: DETAILS_TRANSACTIONS_TOKU_ERROR,
                response: e || {},
            });
        }
    };
