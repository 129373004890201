import React from "react";
import { Grid } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

export const PrepaidOrder = ({ prepaidData }) => {
    // Función para calcular la diferencia entre dos fechas
    const calculateTimeDifference = (date1, date2) => {
        const difference = new Date(date2) - new Date(date1);
        const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hoursDifference = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const totalHoursDifference = Math.round(difference / (1000 * 60 * 60));

        return { daysDifference, hoursDifference, totalHoursDifference };
    };

    // Función para mostrar el mensaje de tiempo restante
    const renderTimeMessage = (difference) => {
        if (difference.daysDifference > 0) {
            return `Faltan ${difference.daysDifference} días para expirar.`;
        } else if (difference.totalHoursDifference > 0) {
            return `Faltan ${difference.totalHoursDifference} horas para expirar.`;
        } else {
            return "Ha expirado.";
        }
    };

    // Calcular la diferencia entre las fechas
    const orderCreated = convertToMexicoTime(prepaidData?.created);
    const offerExpiresAt = prepaidData?.offer_expires_at
        ? convertToMexicoTime(prepaidData.offer_expires_at)
        : "N/A";
    const currentDate = new Date();

    const timeDifference =
        orderCreated && offerExpiresAt
            ? calculateTimeDifference(currentDate, offerExpiresAt)
            : {
                  daysDifference: 0,
                  hoursDifference: 0,
                  totalHoursDifference: 0,
              };

    // Verificar si la orden ha vencido
    const orderExpired = currentDate > new Date(offerExpiresAt);

    return (
        <>
            <Grid item xs={12}>
                <div>
                    <span>Número Dalefon: </span> {prepaidData?.direct_number}
                </div>
                <div>
                    <span>Número de Autorización: </span>{" "}
                    {prepaidData?.authorization_code}
                </div>
                <div>
                    <span>Status:</span> {prepaidData?.status}{" "}
                </div>
                <div>
                    <span>Distribuidor:</span> {prepaidData?.distributor_user} -{" "}
                    {prepaidData?.distributor}{" "}
                </div>
                <div>
                    <span>Mensaje:</span>{" "}
                    {prepaidData?.response_code
                        ? `${prepaidData.response_code}  `
                        : ""}
                    {prepaidData?.response_message}{" "}
                </div>

                <div>
                    <span>Fuente:</span> {prepaidData?.source}{" "}
                </div>
                <hr></hr>
                <div>
                    <p>Se compró el: {orderCreated} </p>
                    <p>Se vence el: {offerExpiresAt} </p>
                    {prepaidData?.offer_expires_at === null ? (
                        <p>Expiración: N/A</p>
                    ) : orderExpired ? (
                        <p>Ha expirado.</p>
                    ) : (
                        <p>{renderTimeMessage(timeDifference)}</p>
                    )}
                </div>
            </Grid>
        </>
    );
};
