import React, { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";

export const ActionBarringHook = (props) => {
    const { actionPostBarringAction, ticketId, imeiNumber } = props || {};

    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    const handleClickBarring = () => {
        setModalOpen(true);
    };
    useEffect(() => {
        const { postUpdateBarringObj } = actionPostBarringAction || {};
        if (actionPostBarringAction.postUpdateBarringObj.length != 0) {
            if (actionPostBarringAction.loading == false) {
                setErrorMssge(postUpdateBarringObj.message);
                setSeverityAlert("error");
                setAlert(true);
                setIsLoading(false);
            } else {
                setSeverityAlert("success");
                setErrorMssge("Barring satisfactorio");
                setAlert(true);
                setIsLoading(false);
            }
        }
    }, [actionPostBarringAction]);

    const handleSubmit = () => {
        setIsLoading(true);
        props.postBarringAction(ticketId);
    };

    const clearFunction = () => {
        setAlert(false);
    };
    return {
        modalOpen,
        setModalOpen,
        handleClickBarring,
        isLoading,
        setIsLoading,
        alert,
        setAlert,
        errorMssge,
        setErrorMssge,
        severityAlert,
        setSeverityAlert,
        handleSubmit,
        clearFunction,
    };
};
