import React from "react";
import { connect } from "react-redux";
import FiltroNetPay from "./list-transactions-netpay/components/FiltroNetPay";
import { netPayAction } from "_models/redux/list-transactions-netpay/action";
import { cleanNetPayAction } from "_models/redux/list-transactions-netpay/clean-action";
import { Box, Typography, Grid } from "@mui/material";
import { PageNetPayHook } from "./list-transactions-netpay/hooks/PageNetPayHook";
import CircularProgress from "@mui/material/CircularProgress";
import { CircularProgressStyle } from "./list-transactions-netpay/style/style";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import { detailNetPayAction } from "_models/redux/detail-transactions-netpay/action";
import { cleanDetailNetPayAction } from "_models/redux/detail-transactions-netpay/clean-action";
import DataTableDetailOrder from "widgets/customDialogComponentDetails/componentDialog";
import { ActiontransactionsType } from "_models/redux/transactions-type/action";

const PageNetPay = ({
    netPayAction,
    netPay,
    cleanNetPayAction,
    cleanDetailNetPayAction,
    detailNetPayAction,
    detailTransactionsNetpay,
    ActiontransactionsType,
    transactionType,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        responseTransactionType,
    } = PageNetPayHook({
        netPayAction,
        cleanNetPayAction,
        netPay,
        cleanDetailNetPayAction,
        detailNetPayAction,
        detailTransactionsNetpay,
        ActiontransactionsType,
        transactionType,
    });

    return (
        <Box>
            <Grid>
                <h4> Transacciones de NetPay </h4>
            </Grid>
            <Box sx={{ mb: 10 }}>
                <FiltroNetPay
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                    responseTransactionType={responseTransactionType}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Box>

            <>
                {loading ? (
                    <CircularProgress
                        sx={CircularProgressStyle.CircularProgress}
                    />
                ) : (
                    <>
                        {mappedRows?.length > 0 ? (
                            <DataTable
                                rows={mappedRows}
                                columns={Columns}
                                onRowClick={handleRowClick}
                                page={page}
                                pageSize={size}
                                rowCount={pagination?.total_rows ?? 0}
                                onPageChange={pagination?.handleChangePage}
                                handleChangePageSize={
                                    pagination?.handleChangePageSize
                                }
                            />
                        ) : (
                            <Typography variant="h6">
                                No hay registros disponibles
                            </Typography>
                        )}
                    </>
                )}
            </>

            <CustomDialog
                fullWidth
                title="Transacción netpay"
                content={
                    <DataTableDetailOrder
                        response={detailTransactionsNetpay?.response}
                    />
                }
                open={isDialogOpenDetails}
                onClose={handleClose}
                maxWidth="md"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    netPay: state?.netPay,
    detailTransactionsNetpay: state?.detailTransactionsNetpay,
    transactionType: state?.transactionType,
});

const mapDispatchToProps = {
    cleanNetPayAction,
    netPayAction,
    cleanDetailNetPayAction,
    detailNetPayAction,
    ActiontransactionsType,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageNetPay);
