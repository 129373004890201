import React, { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";
import { useParams } from "react-router-dom";

export const ActionUnlockImeiHook = (props) => {
    let params = useParams();
    const { directNumber, ticketId, imeiNumber } = props;

    const [modalOpenUnlockImei, setModalOpenUnlockImei] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [severityAlert, setSeverityAlert] = useState("info");

    const [errorMssge, setErrorMssge] = useState("");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setIsLoading(false);

        const unlockImei = props.unlockImeiReducer || undefined;

        if (unlockImei.loading) {
            setSeverityAlert("success");
            setErrorMssge("Desbloqueo de IMEI se realizó satisfactoriamente");
            setAlert(true);
            props.getEvents({ ticket_id: params.ticketId });
        }
    }, [props.unlockImeiReducer]);

    const handleUnlockImei = () => {
        setIsLoading(true);
        props.unlockImei({
            ticket_id: ticketId,
            imei: imeiNumber,
        });
    };

    const closePopup = () => {
        setModalOpenUnlockImei(false);
        setAlert(false);
    };

    return {
        modalOpenUnlockImei,
        setModalOpenUnlockImei,
        isLoading,
        alert,
        errorMssge,
        severityAlert,
        handleUnlockImei,
        closePopup,
        imeiNumber,
    };
};
