import {
    DETAIL_TRANSACTIONS_STP,
    DETAIL_TRANSACTIONS_STP_ERROR,
    CLEAN_DETAIL_TRANSACTIONS_STP,
    CLEAN_DETAIL_TRANSACTIONS_STP_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    response: undefined,
    status: undefined,
    state: undefined,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DETAIL_TRANSACTIONS_STP:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                response: action?.response || {},
                status: action?.status || null,
                state: "success",
            };

        case DETAIL_TRANSACTIONS_STP_ERROR:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                response: action?.response || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_DETAIL_TRANSACTIONS_STP:
            return {
                ...state,
                loaded: false,
                method: undefined,
                response: action?.response || undefined,
                status: undefined,
                state: "success",
            };

        case CLEAN_DETAIL_TRANSACTIONS_STP_ERROR:
            return {
                ...state,
                loaded: false,
                method: undefined,
                response: action?.response || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
}
