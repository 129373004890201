import React, { useContext, useEffect, useState } from "react";
import { LoadersContext } from "_models/context/app";

export const ModalNewIcc = (props) => {
    const { setOpenModal, ticketId } = props || {};
    const [iccNumber, setIccNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");
    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setIsLoading(false);
        const response = props.changeSim.response || { response: undefined };

        if (response.length != 0) {
            if (typeof response.loading == "false") {
            } else {
                setSeverityAlert("success");
                setErrorMssge("El cambió de ICC se realizó satisfactoriamente");
                setAlert(true);
            }
        }
        if (props.changeSim.loading == false) {
            if (typeof props.changeSim.error != "undefined") {
                setErrorMssge(props.changeSim.error.message);
                setSeverityAlert("error");
                setAlert(true);
            }
        }
    }, [props.changeSim]);

    const handleSubmit = () => {
        setIsLoading(true);

        props.postChangeSim({
            ticket_id: ticketId,
            icc_number: iccNumber,
        });
    };

    const onInputChange = (event) => {
        setIccNumber(event.target.value);
    };

    const closePopup = () => {
        props.cleanChangeSim();
        setOpenModal(false);
    };

    return {
        iccNumber,
        isLoading,
        alert,
        errorMssge,
        severityAlert,
        handleSubmit,
        onInputChange,
        closePopup,
    };
};
