import React, { useState, useEffect } from "react";

export const ActionMakePrepaidHooks = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpenMakePrepaid, setIsDialogOpenMakePrepaid] =
        useState(false);
    const handleMakePrepaid = () => {
        setIsLoading(true);
        setIsDialogOpenMakePrepaid(true);
    };

    return {
        isLoading,
        handleMakePrepaid,
        isDialogOpenMakePrepaid,
        setIsDialogOpenMakePrepaid,
    };
};
