import {
    TRANSACTION_TYPES,
    TRANSACTION_TYPES_ERROR,
    CLEAN_TRANSACTION_TYPES,
    CLEAN_TRANSACTION_TYPES_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_TYPES:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };

        case TRANSACTION_TYPES_ERROR:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_TRANSACTION_TYPES:
            return {
                ...state,
                loaded: false,
                method: undefined,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success-clean",
            };

        case CLEAN_TRANSACTION_TYPES_ERROR:
            return {
                ...state,
                loaded: false,
                method: undefined,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error-clean",
            };
        default:
            return state;
    }
}
