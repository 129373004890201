import React from "react";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import NipInput from "./NipInput";
import PhoneInput from "./PhoneInput";

const ModalChangeNirPortability = ({
    onSubmit,
    handleSubmit,
    closePopup,
    state,
    loaded,
    response,
    loaderButton,
    register,
    registerOptions,
    nipValue,
    phoneValue,
    errors,
}) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container className="modalBackground">
                        <Grid item xs={4} className="modalContainer">
                            {state == "success" && loaded ? (
                                <Grid
                                    container
                                    className={
                                        "required-incidence no-before-icon m-0 j-c-c"
                                    }
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className={"d-flex j-c-c"}
                                    >
                                        {
                                            "La portabilidad del número está en proceso"
                                        }
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={"d-flex j-c-c"}
                                    >
                                        <button
                                            className={"m-0"}
                                            onClick={closePopup}
                                            id="cancelBtn"
                                        >
                                            Aceptar
                                        </button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container>
                                    <Grid item xs={6} className="title">
                                        <h3>Portabilidad</h3>
                                    </Grid>
                                    <Grid item xs={6} className="titleCloseBtn">
                                        <button onClick={closePopup}>
                                            <CloseIcon />
                                        </button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NipInput
                                            register={register}
                                            registerOptions={registerOptions}
                                            nipValue={nipValue}
                                            errors={errors}
                                        />
                                        <PhoneInput
                                            register={register}
                                            registerOptions={registerOptions}
                                            phoneValue={phoneValue}
                                            errors={errors}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        className={
                                            "required-incidence no-before-icon m-0"
                                        }
                                    >
                                        {response || ""}
                                    </Grid>

                                    <Grid item xs={12} className="footer">
                                        <button
                                            onClick={closePopup}
                                            id="cancelBtn"
                                        >
                                            Cancelar
                                        </button>
                                        <LoadingButton
                                            size="small"
                                            loading={loaderButton}
                                            loadingPosition="end"
                                            variant="contained"
                                            className={"no-uppercase"}
                                            type="submit"
                                        >
                                            Aceptar
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default ModalChangeNirPortability;
