import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import CustomPaginationTable from "./data/pagination";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

const DataTable = ({
    rows,
    columns,
    onRowClick,
    page,
    pageSize,
    rowCount,
    onPageChange,
    handleChangePageSize,
    getTextColor,
}) => {
    // Función para manejar el clic en una fila
    const handleGridRowClick = (params) => {
        if (onRowClick) {
            onRowClick(params.id);
        }
    };

    // Función para obtener el estilo del texto en función de las condiciones
    const getTextStyle = (params) => {
        if (params.row && (params.row.is_chargeback || params.row.is_fraud)) {
            return { color: "#ffffff" }; // Color blanco para texto en casos específicos
        } else if (getTextColor && params.row) {
            const textColor = getTextColor(params.row);
            return { color: textColor }; // Color personalizado según la función getTextColor
        } else {
            return {}; // Sin estilos adicionales si no hay condiciones especiales
        }
    };

    // Modificar las columnas solo si hay una función getTextColor definida
    const modifiedColumns = getTextColor
        ? columns.map((column) => ({
              ...column,
              renderCell: (params) => (
                  <div
                      style={{
                          backgroundColor: params.row
                              ? params.row.backgroundColor || undefined
                              : undefined,
                          ...getTextStyle(params), // Aplicar estilo de texto según las condiciones
                      }}
                  >
                      {column.field === "created" ? (
                          <div>
                              {convertToMexicoTime(
                                  params.row.toku_transaction_date
                              )}
                          </div>
                      ) : (
                          <div>{params.value}</div>
                      )}
                  </div>
              ),
          }))
        : columns; // Utilizar las columnas originales si no se aplica getTextColor

    return (
        <Box sx={{ height: "100%", maxWidth: "100%" }}>
            <DataGrid
                pagination
                paginationMode="server"
                rows={rows}
                columns={modifiedColumns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={rowCount}
                page={page}
                onPageChange={(newPage) => onPageChange(newPage)}
                onRowClick={handleGridRowClick}
                components={{
                    Pagination: () => (
                        <CustomPaginationTable
                            page={page}
                            pageCount={page}
                            pageSize={pageSize}
                            rowCount={rowCount}
                            onPageChange={onPageChange}
                            onPageSizeChange={handleChangePageSize}
                        />
                    ),
                }}
            />
        </Box>
    );
};

export default DataTable;
