import React from "react";
import { Chip } from "@mui/material";

const SimTypeChip = ({ simTypeName }) => {
    let backgroundColor = "inherit";

    switch (simTypeName) {
        case 1:
        case "SIM":
            backgroundColor = "#A34398";
            simTypeName = "SIM";
            break;
        case "E-SIM":
        case "Esim":
        case 2:
            backgroundColor = "#362e7d";
            simTypeName = "eSIM";
            break;
        case 3:
        case "MiFi":
            backgroundColor = "#e17522";
            simTypeName = "MiFi";
            break;
        case 4:
        case "IoT":
            backgroundColor = "#1863c7";
            simTypeName = "IoT";
            break;
        default:
            simTypeName = "N/A";
            break;
    }

    const style = {
        backgroundColor,
        color: "white",
        border: "1px solid #ccc",
        padding: "4px 12px",
        borderRadius: "8px",
        fontSize: "15px",
    };

    return <Chip label={simTypeName} style={style} />;
};

export default SimTypeChip;
