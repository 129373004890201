import React from "react";
import { Grid, Box, Divider } from "@mui/material";

const ProductDetails = ({ response }) => {
    return (
        <>
            <Grid item xs={6}>
                <div>
                    <span>Producto </span>
                </div>
                <div>
                    <span>Costo</span>{" "}
                </div>
                <div>
                    <span>Costo SIM/eSIM</span>{" "}
                </div>
                <div>
                    <span>Costo envío</span>{" "}
                </div>
                <div>
                    <span>Descuento</span>{" "}
                </div>
            </Grid>
            <Grid item xs={6} className="device-right-data">
                <div> {response?.purchase?.product?.name}</div>
                <div> ${response?.purchase?.product?.prepaid_cost}</div>
                <div> ${response?.purchase?.product?.sim_esim_cost}</div>
                <div> ${response?.purchase?.product?.shipping_cost}</div>
                <div>
                    {response?.purchase?.product?.prepaid_discount &&
                    response?.purchase?.product?.prepaid_discount !== 0
                        ? `- $${response?.purchase?.product?.prepaid_discount}`
                        : `$${
                              response?.purchase?.product?.prepaid_discount || 0
                          }`}
                </div>
            </Grid>
            {/* Divider before "TotalPa Amount" */}
            <Divider orientation="horizontal" />

            <Grid item xs={6}>
                <Box display="flex" justifyContent="space-between">
                    <div style={{ textAlign: "left" }}>
                        <p>Total </p>
                    </div>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" justifyContent="space-between">
                    <div
                        style={{
                            textAlign: "right",
                            marginLeft: "auto",
                            width: "100px",
                        }}
                    >
                        <p>${response?.purchase?.product?.total_amount}</p>
                    </div>
                </Box>
            </Grid>
        </>
    );
};

export default ProductDetails;
