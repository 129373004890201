import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Typography, Chip } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

export const PageNetPayHook = ({
    netPayAction,
    cleanNetPayAction,
    detailNetPayAction,
    netPay,
    detailTransactionsNetpay,
    cleanDetailNetPayAction,
    ActiontransactionsType,
    transactionType,
}) => {
    const { payload } = netPay || {};
    const { response } = detailTransactionsNetpay || {};
    const { rows, pagination } = payload || {};
    const responseTransactionType = transactionType?.payload?.result;

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (response?.response?.status > 200) {
            setDialogMessage("Error");
            setDialogMessage(response?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        ActiontransactionsType();
    }, []);

    useEffect(() => {
        // Verificar si searchParams tiene algún valor
        if (Object.keys(searchParams).length !== 0) {
            setLoading(true);
            netPayAction(page, size, searchParams)
                .then(() => setLoading(false))
                .catch((error) => {
                    setLoading(false);
                });
        }
        return () => {
            cleanNetPayAction();
        };
    }, [page, size, searchParams, netPayAction, cleanNetPayAction]);

    useEffect(() => {
        if (netPay?.status === 200) {
            setLoading(false);
        } else if (netPay?.payload?.response?.status > 200) {
            setDialogMessage(netPay?.payload?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [netPay]);

    const onSubmit = (data) => {
        const {
            from_date,
            to_date,
            authorization_code,
            masked_pan_termination,
        } = data;

        const areDatesProvided = from_date !== "" && to_date !== "";
        const isAuthorizationOrCardProvided =
            authorization_code !== "" || masked_pan_termination !== "";

        if (areDatesProvided || isAuthorizationOrCardProvided) {
            setLoading(true);
            setPage(1);
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(([_, value]) => value !== "")
            );
            setSearchParams(filteredData);
            setErrorMessage("");
        } else {
            setErrorMessage(
                "Por favor, complete al menos uno de los siguientes campos: autorización, tarjeta o ambas fechas."
            );
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    const mappedRows =
        rows?.map((row) => ({
            ...row,
            id: `${row.id}`,
        })) || [];

    const handleIdButtonClick = (id) => {
        setLoading(true);
        if (id) {
            setIsDialogOpenDetails(true);
            detailNetPayAction(id)
                .then(() => setLoading(false))
                .catch((error) => {
                    setDialogMessage("Error");
                    setDialogMessage(
                        "Error al cargar los detalles de la transferencia."
                    );
                    setIsDialogOpen(true);
                    setLoading(false);
                });

            return () => {
                cleanDetailNetPayAction();
            };
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setIsDialogOpenDetails(false);
        cleanDetailNetPayAction();
    };

    const getStatusColor = (paymentStatus) => {
        switch (paymentStatus) {
            case "Success":
                return "green";
            case "Fail":
                return "red";
            case "Review":
                return "Orange";
            default:
                return "purple";
        }
    };

    const translationMap = {
        Requested: "Transacción registrada",
        Success: "Transacción exitosa",
        Fail: "Falló al procesar el pago",
        Review: "En espera de revisión",
        Error: "Error al procesar el pago",
    };

    const Columns = [
        {
            field: "id",
            headerName: "ID",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleIdButtonClick(params.row.id)}
                >
                    {"Ver detalle"}
                </Button>
            ),
        },
        {
            field: "created",
            headerName: "Fecha",
            width: 250,

            headerAlign: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },
        {
            field: "payment_status",
            headerName: "Estado de pago",
            width: 220,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Chip
                    label={
                        translationMap[params.row.payment_status] ||
                        params.row.payment_status
                    }
                    style={{
                        backgroundColor: getStatusColor(
                            params.row.payment_status
                        ),
                        color: "white",
                    }}
                />
            ),
        },
        {
            field: "type_payment_request",
            headerName: "Tipo transacción",
            width: 200,

            headerAlign: "center",
        },

        {
            field: "authorization_code",
            headerName: "Autorización",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "masked_pan",
            headerName: "Tarjeta",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "amount",
            headerName: "Monto",
            width: 120,
            align: "right",
            headerAlign: "center",
            valueGetter: (params) => {
                const formattedAmount = Number(
                    params.row.amount
                ).toLocaleString("es-MX", {
                    style: "currency",
                    currency: "MXN",
                });
                return formattedAmount;
            },
        },
        {
            field: "reference",
            headerName: "Referencia",
            width: 200,
            align: "right",
            headerAlign: "center",
        },

        {
            field: "frictionless_confirmation",
            headerName: "3DS",
            width: 100,

            headerAlign: "center",
            renderCell: (params) => (
                <Typography>
                    {params.row.frictionless_confirmation ? "SI" : "NO"}
                </Typography>
            ),
        },
        {
            field: "message",
            headerName: "Mensaje",
            width: 200,

            headerAlign: "center",
        },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        selectedTransactionId,
        errorMessage,
        setErrorMessage,
        responseTransactionType,
    };
};
