import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import { LoadersContext } from "_models/context/app";

import { cleanLeadsAction } from "_models/redux/leads/clean-action";

export const useHelpdeskDatagrid = (props) => {
    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};
    ////

    const userId = localStorage.getItem("user_id");

    ////

    const history = useNavigate();

    ////

    const {
        leadsObj,
        getLeads,
        leadAssignment,
        setLeadAssignmentAction,
        cleanLeadAssignmentAction,
    } = props || {};

    const { payload, state, status, loaded, method } = leadsObj || {};
    const { leads, pagination } = payload || {};
    const { pages, page, size } = payload || {};

    const [rowCount, setRowCount] = useState(50);

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);

    const [loadingDatagrid, setLoadingDatagrid] = useState(true);

    const [datagridPageSize, setDatagridPageSize] = useState(50);
    const [datagridPage, setDatagridPage] = useState(0);

    const [dateValue, setDateValue] = useState(null);

    useEffect(() => {
        if (dateValue) {
            const formatDateValue = moment(
                dateValue.format("YYYY-MM-DD")
            ).format();
        }
    }, [dateValue]);

    useEffect(() => {
        if (leadAssignment?.status >= 300 && leadAssignment?.status < 500) {
            setOpen(true);
            setIsScreenLoading(false);
        }
        if (leadAssignment) {
            setIsScreenLoading(false);
        }
    }, [leadAssignment]);

    useEffect(() => {
        // getLeads({
        //     page: datagridPage,
        //     size: datagridPageSize,
        //     // contact_name: contact_name,
        //     // contact_phone: contact_form,
        // });

        getLeadsMethod("ue[datagridPage]");
    }, [datagridPage]);

    useEffect(() => {
        setDatagridPage(0);
        getLeadsMethod("ue[datagridPageSize]");
    }, [datagridPageSize]);

    const getLeadsMethod = (from) => {
        const filtersValues = getValues();

        const { nameInput, emailInput, phoneInput } = filtersValues || {};

        let formatDateValue = "";

        if (dateValue) {
            formatDateValue = moment(dateValue.format("YYYY-MM-DD")).format();
        }

        let dateFormatted = "";
        if (formatDateValue.split("T")[0]) {
            dateFormatted = formatDateValue.split("T")[0];
        }

        if (from === "search") {
            setDatagridPage(0);
        }

        getLeads({
            page: from === "search" ? 0 : datagridPage,
            size: datagridPageSize,
            contact_name: nameInput,
            contact_phone: phoneInput,
            contact_email: emailInput,
            date: dateFormatted,
        });
    };

    const changePage = (newPage) => {
        setDatagridPage(newPage);
    };

    const changePageSize = (newPageSize) => {
        setDatagridPageSize(newPageSize);
        setDatagridPage(0);
    };

    useEffect(() => {
        generateDataColumns();

        return () => {
            if (typeof cleanLeadsAction == "function") cleanLeadsAction();

            if (typeof cleanLeadAssignmentAction == "function")
                cleanLeadAssignmentAction();
        };
    }, []);

    useEffect(() => {
        if (rows?.length > 0) setLoadingDatagrid(false);
    }, [rows]);

    useEffect(() => {
        generateDataRows();
    }, [leads]);

    useEffect(() => {
        if (
            leadsObj?.payload?.response?.status >= 300 &&
            leadsObj?.payload?.response?.status < 500
        ) {
            setOpen(true);
        }
        if (leadsObj?.state == "error") {
            setOpen(true);
        }
    }, [leadsObj]);

    useEffect(() => {
        const { pages, size } = pagination || {};

        if (parseInt(pages) > 0) {
            // setRowCount(pages);
            setRowCount(parseInt(pages) * parseInt(size));
        }
    }, [pagination]);

    useEffect(() => {
        const { id, message } = leadAssignment?.payload || {};

        if (message == "success" && id) {
            const link = "/helpdesk-detail/";
            // + id;
            history(link);
        }
    }, [leadAssignment]);

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));

    const goToLink = (leadId) => {
        if (leadId) {
            setIsScreenLoading(true);
            setLeadAssignmentAction({ lead_id: leadId });
            // const link = "/lead-detail/" + id;
        }
    };

    const generateDataColumns = () => {
        let leadsColumns = [
            {
                field: "id",
                headerName: "Lead ID",
                width: 200,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { id, backoffice_user_id } = row || {};

                    return parseInt(userId) === parseInt(backoffice_user_id) ||
                        backoffice_user_id === null ? (
                        <div
                            className={
                                parseInt(userId) ===
                                parseInt(backoffice_user_id)
                                    ? "selected"
                                    : ""
                            }
                        >
                            <span
                                onClick={() => goToLink(id)}
                                className={"lead-detail-link"}
                            >
                                {id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <span
                                className={"lead-detail-link assigned-to-other"}
                            >
                                {id}
                            </span>
                        </div>
                    );
                },
            },
            {
                field: "status_backoffice",
                headerName: "Back Office Estatus",
                width: 310,
            },
            // {
            //     field: "reminderTime",
            //     headerName: "Fecha de recordatorio",
            //     width: 350,
            // },
            {
                field: "contact_name",
                headerName: "Nombre de contacto",
                width: 310,
            },
            {
                field: "creationTime",
                headerName: "Fecha de creación",
                width: 310,
            },
            {
                field: "updatedTime",
                headerName: "Fecha de actualización",
                width: 310,
            },
        ];

        setColumns(leadsColumns);
    };

    const generateDataRows = () => {
        let leadsRows = [];

        if (leads != undefined) {
        }

        leads?.map((element, index) => {
            leadsRows.push({
                id: element?.id,
                updatedTime: element?.updated
                    ? moment(parseInt(element.updated) * 1000).format(
                          "DD/MM/YYYY HH:mm:ss"
                      )
                    : "",
                creationTime: element?.created
                    ? moment(parseInt(element.created) * 1000).format(
                          "DD/MM/YYYY HH:mm:ss"
                      )
                    : "",
                // reminderTime: element?.updated,
                contact_name: element?.contact_name,
                status_backoffice: element?.lead_backoffice_status,
                backoffice_user_id: element?.backoffice_user_id,
            });
        });

        setRows(leadsRows);
    };

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    const handleClose = () => {
        setOpen(false);
        setIsScreenLoading(false);
    };

    const PopModalError = () => {
        return (
            <div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Error
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {leadAssignment?.payload?.detail ||
                                leadsObj?.payload?.response?.data?.detail || (
                                    <h1>Oops algo salió mal....</h1>
                                )}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        );
    };

    const registerOptions = {
        nameInput: {
            // required: "Nombre es requerido",
            minLength: {
                value: 3,
                message: "Mínimo 3 caracteres",
            },
            maxLength: {
                value: 150,
                message: "Máximo 150 caracteres",
            },
        },

        phoneInput: {
            // required: "Número telefónico es requerido",
            pattern: {
                value: /^(0|[0-9]\d*)(\.\d+)?$/,
                message: "El formato telefónico no es correcto",
            },
        },

        emailInput: {
            // required: "El correo electrónico es requerido",
            pattern: {
                value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/,
                message:
                    "El valor ingresado no coincide con el formato del correo electrónico",
            },
        },
    };

    const inputChange = (e) => {};
    const [nameShrink, setNameShrink] = useState();
    const [phoneShrink, setPhoneShrink] = useState();

    const [emailShrink, setEmailShrink] = useState();

    const onSubmit = (e) => {
        // if (e?.nameInput || e?.phoneInput || e?.emailInput || dateValue) {
        getLeadsMethod("search");
        // }
    };

    const cleanAll = () => {
        setDateValue(null);
        reset({
            nameInput: "",
            phoneInput: "",
            emailInput: "",
        });
    };

    const datagridRegister = {
        "& .MuiDataGrid-columnHeaders": {
            position: "sticky",
            zIndex: "999",
            background: "white",
        },
        "& .MuiDataGrid-virtualScroller": {
            marginTop: "0!important",
        },

        "& .MuiDataGrid-main": {
            overflow: "hidden",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            width: "0.4em",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
            background: "#f1f1f1",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
            backgroundColor: "#E7DEE7",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
            background: "#DFD4DF",
        },
    };

    return {
        columns,
        rows,
        datagridPageSize,
        rowCount,
        datagridPage,
        loadingDatagrid,
        changePage,
        changePageSize,
        datagridRegister,
        PopModalError,
    };
};
