import React, { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";

export const ActionPortabilityHook = (props) => {
    const {
        ticketId,
        postPortabilityAction,
        cleanPortabilityAction,
        portability,
    } = props;
    const { loaded, payload, status, state } = portability || {};
    const [modalOpen, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loaderButton, setLoaderButton] = useState(false);
    const [response, setResponse] = useState("");
    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};
    const [nipValue, setNipValue] = useState();
    const [phoneValue, setPhoneValue] = useState();

    const {
        reset,
        value,
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (e) => {
        const post = {
            nip: parseInt(e?.nipInput),
            number_to_port: e?.phoneInput,
        };

        if (typeof postPortabilityAction == "function") {
            postPortabilityAction(ticketId, post);
            setLoaderButton(true);
        }
    };

    const handleClickButton = () => {
        setIsLoading(true);
        setOpenModal(true);
    };

    const closePopup = () => {
        cleanPortabilityAction();
        setOpenModal(false);
        setIsLoading(false);
        setResponse("");
        reset();
    };

    useEffect(() => {
        cleanPortabilityAction();
        return () => {
            cleanPortabilityAction();
        };
    }, []);

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const { message, additional_details, context, detail, title, type } =
            payload || {};

        if (loaderButton && loaded) {
            setResponse(
                <Grid container className={"m-t-15 response-container"}>
                    <Grid item xs={12} className={"response-title"}>
                        {title || ""}
                    </Grid>
                    <Grid item xs={12} className={"response-text"}>
                        {detail || message || ""}
                    </Grid>
                </Grid>
            );
            setLoaderButton(false);
        }
    }, [portability]);

    useEffect(() => {
        if (modalOpen) {
            setIsLoading(false);
        }
    }, [modalOpen]);

    const registerOptions = {
        nipInput: {
            required: "NIP es requerido",
            // minLength: {
            //     value: 4,
            //     message: "mínimo 4 caracteres",
            // },
            maxLength: {
                value: 4,
                message: "máximo 4 caracteres",
            },
        },
        phoneInput: {
            required: "Número de celular es requerido",
            // minLength: {
            //     value: 10,
            //     message: "mínimo 10 caracteres",
            // },
            maxLength: {
                value: 10,
                message: "máximo 10 caracteres",
            },
        },
    };

    return {
        onSubmit,
        handleClickButton,
        closePopup,
        response,
        modalOpen,
        isLoading,
        loaderButton,
        nipValue,
        phoneValue,
        register,
        errors,
        registerOptions,
        handleSubmit,
        state,
        loaded,
        setOpenModal,
    };
};
