import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import { connect } from "react-redux";

const OrdersHeader = (props) => {
    return (
        <Grid container className="page-header">
            <Grid item xs={12}>
                <h3>Órdenes de compra</h3>
            </Grid>
        </Grid>
    );
};

// export default Modal;
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersHeader);
