import React from "react";
import { Grid, Divider, Box } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

const PrepaidOrderDetails = ({ prepaidData }) => {
    return (
        <>
            <Grid item xs={6}>
                <div>
                    <span>Producto</span>{" "}
                </div>
                <div>
                    <span>Costo SIM/eSIM</span>{" "}
                </div>

                <div>
                    <span>Descuento</span>{" "}
                </div>
            </Grid>
            <Grid item xs={6} className="device-right-data">
                <div> {prepaidData?.product_name}</div>
                <div> ${prepaidData?.product_amount}</div>
                <div>
                    {prepaidData?.product_discount &&
                    prepaidData?.product_discount !== 0
                        ? `- $${prepaidData?.product_discount}`
                        : `$${prepaidData?.product_discount || 0}`}
                </div>
            </Grid>
            {/* Divider before "TotalPa Amount" */}
            <Divider orientation="horizontal" />

            <Grid item xs={6}>
                <Box display="flex" justifyContent="space-between">
                    <div style={{ textAlign: "left" }}>
                        <p>Total </p>
                    </div>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" justifyContent="space-between">
                    <div
                        style={{
                            textAlign: "right",
                            marginLeft: "auto",
                            width: "100px",
                        }}
                    >
                        <p>${prepaidData?.product_amount}</p>
                    </div>
                </Box>
            </Grid>
        </>
    );
};

export default PrepaidOrderDetails;
