import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import CustomDialog from "widgets/component/CustomDialog";
import { ActionMakePrepaidHooks } from "../hooks/actionsTicketsHooks/ActionMakePrepaidHooks";
import CustomComponenteMakePrepaid from "./CustomComponenteMakePrepaid";
import { ActionMakePrepaid } from "_models/redux/makePrepaid/action";
import { ActionProductPrepaid } from "_models/redux/products_prepaid/action";
import { listcommentsTikets } from "_models/redux/request-list-comments/action";

const MakePrepaid = (props) => {
    const {
        ticketId,
        ActionProductPrepaid,
        productsPrepaid,
        ActionMakePrepaid,
        makePrepaid,
        listcommentsTikets,
    } = props;

    const {
        handleMakePrepaid,
        isDialogOpenMakePrepaid,
        setIsDialogOpenMakePrepaid,
    } = ActionMakePrepaidHooks();

    useEffect(() => {
        ActionProductPrepaid();
    }, []);

    return (
        <>
            <LoadingButton
                fullWidth
                property="textAlign"
                size="small"
                onClick={handleMakePrepaid}
                variant="outlined"
                className="button"
            >
                <h4>Aplicar paquete</h4>
            </LoadingButton>
            <CustomDialog
                title="Aplicar paquete"
                content={
                    <CustomComponenteMakePrepaid
                        listcommentsTikets={listcommentsTikets}
                        makePrepaid={makePrepaid}
                        ticketId={ticketId}
                        ActionMakePrepaid={ActionMakePrepaid}
                        productsPrepaid={productsPrepaid}
                        ActionProductPrepaid={ActionProductPrepaid}
                    />
                }
                onClose={() => setIsDialogOpenMakePrepaid(false)}
                open={isDialogOpenMakePrepaid}
                fullWidth
                maxWidth=""
                showCloseButton={false}
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: () => setIsDialogOpenMakePrepaid(false),
                        buttonColor: "#724ECB",
                        textColor: "white",
                    },
                ]}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    makePrepaid: state.makePrepaid,
    productsPrepaid: state.productsPrepaid,
});
const mapDispatchToProps = {
    ActionMakePrepaid,
    ActionProductPrepaid,
    listcommentsTikets,
};

export default connect(mapStateToProps, mapDispatchToProps)(MakePrepaid);
