import axiosTokenInstance from "services/api/backoffice";
import {
    LIST_TRANSFER_STP,
    LIST_TRANSFER_STP_ERROR,
} from "_models/redux/types";

// Action function

export const listSTPAction =
    (page = 1, size = 10, filters = {}) =>
    async (dispatch) => {
        try {
            const params = { page, size, ...filters };
            const response = await axiosTokenInstance.get(
                `api/reports/transactions-stp`,
                { params }
            );

            const { data, status } = response || {};

            dispatch({
                type: LIST_TRANSFER_STP,
                payload: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: LIST_TRANSFER_STP_ERROR,
                payload: e || {},
            });
        }
    };
