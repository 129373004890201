import React from "react";

//React redux:
import { connect } from "react-redux";

//Material UI:
import { Box, CircularProgress, Typography } from "@mui/material";

//Actions:
import { atnPortabilityAction } from "_models/redux/atn-portability/action";
import { cleanAtnPortabilityAction } from "_models/redux/atn-portability/clean-action";

//Hooks:
import { usePortabilityPageHook } from "./hooks/usePortabilityPageHook";

//Styles:
import { CircularProgressStyle } from "modules/transactions-netpay/list-transactions-netpay/style/style";

//Components:
import CustomDialog from "widgets/component/CustomDialog";
import DataTable from "widgets/datagrid/DataTable";
import SectionFilterComponent from "./components/SectionFilterComponent";

function PortabilityPageComponent({
    atnPortabilityAction,
    cleanAtnPortabilityAction,
    atnPortability,
}) {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        errorMessage,
    } = usePortabilityPageHook({
        atnPortabilityAction,
        cleanAtnPortabilityAction,
        atnPortability,
    });
    return (
        <Box>
            <h4> Portabilidades </h4>
            <Box sx={{ mb: 10 }}>
                <SectionFilterComponent
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Box>

            <>
                {loading ? (
                    <CircularProgress
                        sx={CircularProgressStyle.CircularProgress}
                    />
                ) : (
                    <>
                        {mappedRows?.length > 0 ? (
                            <DataTable
                                rows={mappedRows}
                                columns={Columns}
                                onRowClick={handleRowClick}
                                page={page}
                                pageSize={size}
                                rowCount={pagination?.total_rows ?? 0}
                                onPageChange={pagination?.handleChangePage}
                                handleChangePageSize={
                                    pagination?.handleChangePageSize
                                }
                            />
                        ) : (
                            <Typography variant="h6">
                                No hay registros disponibles
                            </Typography>
                        )}
                    </>
                )}
            </>

            {/* <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            /> */}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    atnPortability: state?.atnPortability,
});

const mapDispatchToProps = {
    atnPortabilityAction,
    cleanAtnPortabilityAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PortabilityPageComponent);
