import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
export const TicketStatusHook = (props) => {
    const [value, setValue] = useState(2);
    const [customerStatus, setCustomerStatus] = useState([]);
    const [ticketId, setTicketId] = useState();
    let params = useParams();
    useEffect(() => {
        props.getStatusList();
        setTicketId(params.ticketId);
    }, []);

    useEffect(() => {
        const response = props.ticketById.response || undefined;
        if (typeof response != "undefined") {
            setValue(response.status_id);
        }
    }, [props.ticketById]);

    useEffect(() => {
        const { response } = props.statusList || { respose: undefined };
        const status = response.status || undefined;

        if (typeof status != "undefined") {
            setCustomerStatus(status);
        }
    }, [props.statusList]);

    const handleChange = (event) => {
        setValue(event.target.value);
        props.changeStatusTicket({
            ticketId: ticketId,
            statusId: event.target.value,
        });
    };

    return {
        value,
        customerStatus,
        handleChange,
    };
};
