import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

export const PageSTPHook = ({
    listSTPAction,
    cleanTransferSTPClean,
    detailSTPAction,
    listTransactionsSTP,
    detailsTransactionsSTP,
    cleanDetailSTPAction,
}) => {
    const { payload } = listTransactionsSTP || {};
    const { response } = detailsTransactionsSTP || {};
    const { rows, pagination } = payload || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (response?.response?.status > 200) {
            setDialogMessage("Error");
            setDialogMessage(response?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        // Verificar si searchParams tiene algún valor
        if (Object.keys(searchParams).length !== 0) {
            setLoading(true);
            listSTPAction(page, size, searchParams)
                .then(() => setLoading(false))
                .catch((error) => {
                    setLoading(false);
                });
        }
        return () => {
            cleanTransferSTPClean();
        };
    }, [page, size, searchParams, listSTPAction, cleanTransferSTPClean]);

    useEffect(() => {
        if (listTransactionsSTP?.status === 200) {
            setLoading(false);
        } else if (listTransactionsSTP?.payload?.response?.status > 200) {
            setDialogMessage(
                listTransactionsSTP?.payload?.response?.data?.detail
            );
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [listTransactionsSTP]);

    const onSubmit = (data) => {
        const { from_date, to_date, beneficiary_account, reference } = data;

        const areDatesProvided = from_date !== "" && to_date !== "";
        const isAuthorizationOrCardProvided =
            beneficiary_account !== "" || reference !== "";

        if (areDatesProvided || isAuthorizationOrCardProvided) {
            setLoading(true);
            setPage(1);
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(([_, value]) => value !== "")
            );
            setSearchParams(filteredData);
            setErrorMessage("");
        } else {
            setErrorMessage(
                "Por favor, complete al menos uno de los siguientes campos: Referencia, Clave STP o ambas fechas."
            );
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    const mappedRows =
        rows?.map((row) => ({
            ...row,
            id: `${row.id}`,
        })) || [];

    const handleIdButtonClick = (id) => {
        setLoading(true);
        if (id) {
            setIsDialogOpenDetails(true);
            detailSTPAction(id)
                .then(() => setLoading(false))
                .catch((error) => {
                    setDialogMessage("Error");
                    setDialogMessage(
                        "Error al cargar los detalles de la transferencia."
                    );
                    setIsDialogOpen(true);
                    setLoading(false);
                });

            return () => {
                cleanDetailSTPAction();
            };
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setIsDialogOpenDetails(false);
        cleanDetailSTPAction();
    };

    const Columns = [
        {
            field: "id",
            headerName: "ID",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleIdButtonClick(params.row.id)}
                >
                    {"Ver detalle"}
                </Button>
            ),
        },
        {
            field: "created",
            headerName: "Fecha",
            width: 250,

            headerAlign: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },
        {
            field: "payer_name",
            headerName: "Ordenante",
            width: 220,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "order_tracking_key",
            headerName: "clave de rastreo",
            width: 200,

            headerAlign: "center",
        },

        {
            field: "payment_concept",
            headerName: "Concepto pago",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "beneficiary_account",
            headerName: "Cuenta",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "amount",
            headerName: "Monto",
            width: 120,
            align: "right",
            headerAlign: "center",
            valueGetter: (params) => {
                const formattedAmount = Number(
                    params.row.amount
                ).toLocaleString("es-MX", {
                    style: "currency",
                    currency: "MXN",
                });
                return formattedAmount;
            },
        },
        {
            field: "reference",
            headerName: "Referencia",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        selectedTransactionId,
        errorMessage,
        setErrorMessage,
    };
};
