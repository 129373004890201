import React, { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";
import { useParams } from "react-router-dom";

export const ActionSuspendLineHook = (props) => {
    const { actionPostBarringAction, ticketId, directNumber } = props || {};
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    let params = useParams();

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    const handleClickSuspendLine = () => {
        setModalOpen(true);
    };
    useEffect(() => {
        const actionSuspendLine = props.actionSuspendLineReducer || {};

        if (actionSuspendLine.actionSuspendLine.length != 0) {
            if (actionSuspendLine.loading == false) {
                setErrorMssge("Error");
                setSeverityAlert("error");
                setAlert(true);
                setIsLoading(false);
            } else {
                setSeverityAlert("success");
                setErrorMssge("Se suspendió la línea satisfactoriamente");
                setAlert(true);
                setIsLoading(false);
                props.getEvents({ ticket_id: params.ticketId });
            }
        }
    }, [props.actionSuspendLineReducer]);

    const handleSubmit = () => {
        setIsLoading(true);
        props.actionSuspendLine(ticketId);
    };
    const clearFunction = () => {
        setAlert(false);
    };

    return {
        modalOpen,
        setModalOpen,
        isLoading,
        setIsLoading,
        alert,
        setAlert,
        errorMssge,
        setErrorMssge,
        severityAlert,
        setSeverityAlert,
        handleClickSuspendLine,
        handleSubmit,
        clearFunction,
    };
};
