import { connect } from "react-redux";
import {
    Grid,
    Button,
    DialogContent,
    Dialog,
    TextField,
    CircularProgress,
    Divider,
    Typography,
    DialogContentText,
    DialogTitle,
    DialogActions,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { getEsimQr } from "_models/redux/esim-qr/action";
import { getSaleOrderDetailAction } from "_models/redux/sales-detail-order/action";
import { cleanChangeSimToEsimAction } from "_models/redux/change-sim-esim/clean-action";
import { cleanChangeEsimToSimAction } from "_models/redux/change-esim-sim/clean-action";
import { changeSimToEsimAction } from "_models/redux/change-sim-esim/action";
import { changeEsimToSimAction } from "_models/redux/change-esim-sim/action";
import { municipalityAction } from "_models/redux/sepomex-municipality/action";
import { neighborhoodAction } from "_models/redux/sepomex-neighborhood/action";
import { DataTableDetailOrderController } from "../../hook/DataTableDetailOrderController";
import { simActivationAction } from "_models/redux/sim-activation/action";
import { postTransactionsFraud } from "_models/redux/detail-transaction-fraud/action";
import { postTransactionsChargeback } from "_models/redux/detail-transaction-chargeback/action";
import { cleanTransactionChargeback } from "_models/redux/detail-transaction-chargeback/clean_action.js";
import { cleanTransactionFraud } from "_models/redux/detail-transaction-fraud/clean_action";

const DataTableDetailOrder = (props) => {
    const {
        saleOrderDetail,
        getEsimQr,
        saleOrderEsimQr,
        municipality,
        municipalityAction,
        neighborhoodAction,
        neighborhood,
        getSaleOrderDetailAction,
        cleanChangeSimToEsimAction,
        cleanChangeEsimToSimAction,
        changeSimToEsimAction,
        changeEsimToSimAction,
        changeSimToEsim,
        changeEsimToSim,
        simActivationAction,
        simActivation,
        postTransactionsChargeback,
        postTransactionsFraud,
        cleanTransactionChargeback,
        detailTransactionChargeback,
        detailTransactionFraud,
        cleanTransactionFraud,
    } = props || {};
    const {
        isDialogOpen,
        description,
        stateShrink,
        municipalityShrink,
        neighborhoodShrink,
        newOne,
        newTwo,
        newThree,
        addressShrink,
        zipcodeShrink,
        isCustomDialogOpen,
        contactPhoneShrink,
        states,
        dialogMessage,
        copySuccess,
        dateSale,
        handleOpenDialog,
        handleCloseDialog,
        handleCopyToClipboard,
        handleDescriptionChange,
        CustomDialog,
        inputChange,
        labelSimType,
        order_details,
        client,
        device,
        product,
        payment,
        register,
        registerOptions,
        municipalities,
        errors,
        neighborhoods,
        setIsCustomDialogOpen,
        simOrderId,
        handleRequest,
        loading,
        referenceInput,
        handleSubmit,
        setValue,
        handleFraudPrevention,
        handleChargebackPrevention,
        handleClose,
        openActionFraud,
        setOpenActionFraud,
        handleOpenPopUp,
        handleConfirm,
        setDescriptionTransaction,
        setError,
        error,
        isError,
        setLoadingFraudAndChargeback,
        loadingFraudAndChargeback,
    } = DataTableDetailOrderController({
        saleOrderDetail,
        getEsimQr,
        saleOrderEsimQr,
        municipality,
        municipalityAction,
        neighborhoodAction,
        neighborhood,
        getSaleOrderDetailAction,
        cleanChangeSimToEsimAction,
        cleanChangeEsimToSimAction,
        changeSimToEsimAction,
        changeEsimToSimAction,
        changeSimToEsim,
        changeEsimToSim,
        simActivationAction,
        simActivation,
        postTransactionsChargeback,
        postTransactionsFraud,
        cleanTransactionChargeback,
        detailTransactionChargeback,
        detailTransactionFraud,
        cleanTransactionFraud,
    });

    return (
        <Grid container spacing={5} className="device-info-container m-b-15">
            <Grid
                container
                className="device-info"
                xl={12}
                style={{
                    marginLeft: "50px",
                    marginTop: "50px",
                }}
            >
                <Grid item xs={6}>
                    <Typography variant="body1">
                        <strong>Número Dalefon:</strong>{" "}
                        {order_details?.direct_number}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">
                        <strong>Lead ID:</strong> {order_details?.lead_id}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">
                        <strong>Número de orden:</strong>{" "}
                        {order_details?.order_number}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">
                        <strong>Fecha de compra:</strong> {dateSale}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={6} direction={"column"}>
                <Grid container className="device-info">
                    <Grid item xs={12}>
                        <h2>Cliente</h2>
                        <div>
                            <span>Nombre:</span> {client?.name}{" "}
                        </div>
                        <div>
                            <span>Teléfono:</span> {client?.phone}
                        </div>
                        <div>
                            <span>Correo:</span> {client?.email}{" "}
                        </div>
                        <div>
                            <span>Dirección:</span> {client?.address}{" "}
                        </div>
                        <hr></hr>
                        <div>
                            <span>Correo del ticket:</span>{" "}
                            {client?.ticket_email}
                        </div>
                    </Grid>
                </Grid>
                <h2></h2>
                <Grid container className="device-info">
                    <Grid container>
                        <Grid item xs={12}>
                            <div>
                                <strong>Equipo:</strong> {device?.brand}
                            </div>
                            <div>
                                <strong>Modelo:</strong> {device?.model}
                            </div>
                            <div>
                                <strong>IMEI:</strong> {device?.imei}
                            </div>
                            <Grid item xs={12} className="device-right-data">
                                <Grid
                                    container
                                    className="type-label"
                                    id={labelSimType()}
                                >
                                    <Grid item xs={12}>
                                        <div>{labelSimType()}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <h2></h2>
                <Grid item xs={6}>
                    <Grid container className="device-info">
                        <Grid item xs={12}>
                            <h2>Prevención</h2>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={6}>
                                <div>Fraude</div>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleOpenPopUp("fraud")}
                                    style={{ margin: "10px" }}
                                >
                                    Reportar
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={6}>
                                <div>Contracargo</div>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                <Button
                                    variant="outlined"
                                    onClick={() =>
                                        handleOpenPopUp("chargeback")
                                    }
                                    style={{ margin: "10px" }}
                                >
                                    Reportar
                                </Button>
                            </Grid>
                        </Grid>{" "}
                    </Grid>
                </Grid>
            </Grid>

            <h2></h2>

            <Grid item xs={6} direction={"column"}>
                <Grid container className="device-info">
                    <Grid item xs={12}>
                        <h2>Resumen de compra</h2>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <span>Producto</span>
                        </div>
                        <div>
                            <span>Costo</span>
                        </div>
                        <div>
                            <span>Costo SIM/eSIM</span>
                        </div>
                        <div>
                            <span>Costo envío</span>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ textAlign: "right" }}>
                            {product?.name}
                        </div>
                        <div style={{ textAlign: "right" }}>
                            ${product?.prepaid_cost}
                        </div>
                        <div style={{ textAlign: "right" }}>
                            ${product?.sim_esim_cost}
                        </div>
                        <div style={{ textAlign: "right" }}>
                            ${product?.shipping_cost}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <p>Total</p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ textAlign: "right" }}>
                            <p>${product?.total_amount}</p>
                        </div>
                    </Grid>
                </Grid>
                <h2></h2>
                <Grid container className="device-info">
                    <Grid item xs={12}>
                        <h2>Forma de pago</h2>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <span>Tarjeta</span>
                        </div>
                        <div>
                            <span>No. de autorización</span>
                        </div>
                        <div>
                            <span>Ejecutivo</span>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ textAlign: "right" }}>
                            {payment?.maked_card}
                        </div>
                        <div style={{ textAlign: "right" }}>
                            {payment?.authorization_number}
                        </div>
                        <div style={{ textAlign: "right" }}>
                            {order_details?.backoffice_user_name}
                        </div>
                    </Grid>
                </Grid>

                <h2></h2>

                <Grid
                    container
                    className={
                        order_details?.sim_type_id === 2
                            ? "device-info"
                            : "hidden-element"
                    }
                >
                    <Grid item xs={12}>
                        <div>
                            <strong>Acciones</strong>
                        </div>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <div>URL del QR</div>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                startIcon={<ContentPasteIcon />}
                                onClick={() =>
                                    getEsimQr(order_details?.sim_order_id)
                                }
                            >
                                Copiar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <div>Cambio eSIM a SIM</div>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={handleOpenDialog}>Cambiar</Button>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <div>Activación SIM</div>
                        </Grid>
                        <Grid item xs={6}>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    onClick={() =>
                                        handleRequest(
                                            order_details?.sim_order_id
                                        )
                                    }
                                >
                                    Activar
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {/* device-info 
                //TODO: agregar funcionalidad de reembolso
                */}

                <Grid container className="hidden-element">
                    <Grid item xs={6}>
                        <span>
                            <div>Reembolso</div>
                        </span>
                    </Grid>
                    <Grid item xs={6} className="device-right-data">
                        <span>
                            <p>-ButtonGenerar-</p>
                        </span>
                    </Grid>
                </Grid>

                {isDialogOpen && (
                    <Dialog open={isDialogOpen}>
                        <DialogContent style={{ width: "500px" }}>
                            <form
                                onSubmit={handleSubmit(handleCopyToClipboard)}
                            >
                                <TextField
                                    {...register(
                                        "description",
                                        registerOptions?.description
                                    )}
                                    label="Descripción"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />

                                <span>
                                    {errors?.description && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.description.message}
                                        </p>
                                    )}
                                </span>

                                {order_details?.sim_type_id === 2 && (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            className={"lead-form-input-error"}
                                        >
                                            <span>
                                                {/* {errors?.zipcodeInput && (
                                                <p className="required-incidence m-0">
                                                    {" "}
                                                    {
                                                        errors?.zipcodeInput
                                                            .message
                                                    }
                                                </p>
                                            )} */}
                                            </span>
                                        </Grid>
                                        {/* Mostrar estos TextField solo si sim_type_id es 2 */}
                                        <TextField
                                            {...register("stateInput", {
                                                required:
                                                    "El estado es requerido",
                                            })}
                                            select
                                            fullWidth
                                            label="Seleccione un estado"
                                            variant="outlined"
                                            placeholder="Estado"
                                            id="stateInput"
                                            name="stateInput"
                                            value={newOne}
                                            onChange={(e) => {
                                                if (e.target.value === 0) {
                                                    setValue(
                                                        "stateInput",
                                                        undefined
                                                    );
                                                } else {
                                                    setValue(
                                                        "stateInput",
                                                        e.target.value
                                                    );
                                                }
                                                inputChange(e);
                                            }}
                                            InputLabelProps={{
                                                shrink: stateShrink,
                                            }}
                                            className="m-tb-20 form-control data-input"
                                            sx={{
                                                mb: 2,
                                                "& .MuiOutlinedInput-root:hover":
                                                    {
                                                        "& > fieldset": {
                                                            borderColor:
                                                                "#6C63FF",
                                                        },
                                                    },
                                            }}
                                        >
                                            <MenuItem key={0} value={0}>
                                                {"Seleccione un estado"}
                                            </MenuItem>
                                            {states?.map((option) => (
                                                <MenuItem
                                                    key={option?.id}
                                                    value={option?.id || 0}
                                                >
                                                    {option?.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <span>
                                            {errors?.stateInput && (
                                                <p className="required-incidence m-0">
                                                    {errors?.stateInput.message}
                                                </p>
                                            )}
                                        </span>
                                        <TextField
                                            {...register("municipalityInput", {
                                                required:
                                                    "El municipio es requerido",
                                            })}
                                            select
                                            fullWidth
                                            label="Seleccione un municipio"
                                            variant="outlined"
                                            placeholder="Municipio"
                                            id="municipalityInput"
                                            name="municipalityInput"
                                            value={newTwo}
                                            onChange={(e) => {
                                                if (e.target.value === 0) {
                                                    setValue(
                                                        "municipalityInput",
                                                        undefined
                                                    );
                                                } else {
                                                    setValue(
                                                        "municipalityInput",
                                                        e.target.value
                                                    );
                                                }
                                                inputChange(e);
                                            }}
                                            InputLabelProps={{
                                                shrink: municipalityShrink,
                                            }}
                                            className="m-tb-20 form-control data-input"
                                            sx={{
                                                mb: 2,
                                                "& .MuiOutlinedInput-root:hover":
                                                    {
                                                        "& > fieldset": {
                                                            borderColor:
                                                                "#6C63FF",
                                                        },
                                                    },
                                            }}
                                        >
                                            <MenuItem key={0} value={0}>
                                                {"Seleccione un municipio"}
                                            </MenuItem>
                                            {municipalities?.map((option) => (
                                                <MenuItem
                                                    key={option?.id}
                                                    value={option?.id || 0}
                                                >
                                                    {option?.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <Grid
                                            item
                                            xs={12}
                                            className={"lead-form-input-error"}
                                        >
                                            <span>
                                                {errors?.municipalityInput && (
                                                    <p className="required-incidence m-0">
                                                        {
                                                            errors
                                                                ?.municipalityInput
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </span>
                                        </Grid>
                                        <TextField
                                            {...register("neighborhoodInput", {
                                                required:
                                                    "La colonia es requerida",
                                            })}
                                            select
                                            label="Seleccione una colonia"
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Colonia"
                                            id="neighborhoodInput"
                                            name="neighborhoodInput"
                                            onChange={(e) => {
                                                if (e.target.value === 0) {
                                                    setValue(
                                                        "neighborhoodInput",
                                                        undefined
                                                    );
                                                } else {
                                                    setValue(
                                                        "neighborhoodInput",
                                                        e.target.value
                                                    );
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: neighborhoodShrink,
                                            }}
                                            className="m-tb-20 form-control data-input"
                                            sx={{
                                                mb: 2,
                                                "& .MuiOutlinedInput-root:hover":
                                                    {
                                                        "& > fieldset": {
                                                            borderColor:
                                                                "#6C63FF",
                                                        },
                                                    },
                                            }}
                                        >
                                            <MenuItem key={0} value={0}>
                                                {"Seleccione una colonia"}
                                            </MenuItem>
                                            {neighborhoods?.map((option) => (
                                                <MenuItem
                                                    key={option?.id}
                                                    value={option?.id || 0}
                                                >
                                                    {option?.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <Grid
                                            item
                                            xs={12}
                                            className={"lead-form-input-error"}
                                        >
                                            <span>
                                                {errors?.neighborhoodInput && (
                                                    <p className="required-incidence m-0">
                                                        {
                                                            errors
                                                                ?.neighborhoodInput
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </span>
                                        </Grid>
                                        <TextField
                                            {...register(
                                                "zipcodeInput",
                                                registerOptions?.zipcodeInput
                                            )}
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Código postal"
                                            placeholder="Código postal"
                                            id="zipcodeInput"
                                            name="zipcodeInput"
                                            onChange={(e) => inputChange(e)}
                                            InputLabelProps={{
                                                shrink: zipcodeShrink,
                                            }}
                                            className="m-tb-20 form-control data-input"
                                            sx={{
                                                mb: 2,
                                                "& .MuiOutlinedInput-root:hover":
                                                    {
                                                        "& > fieldset": {
                                                            borderColor:
                                                                "#6C63FF",
                                                        },
                                                    },
                                            }}
                                        />

                                        <Grid
                                            item
                                            xs={12}
                                            className={"lead-form-input-error"}
                                        >
                                            <span>
                                                {errors?.zipcodeInput && (
                                                    <p className="required-incidence m-0">
                                                        {" "}
                                                        {
                                                            errors?.zipcodeInput
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </span>
                                        </Grid>
                                        <TextField
                                            {...register(
                                                "addressInput",
                                                registerOptions?.addressInput
                                            )}
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Calle y número int/ext"
                                            placeholder="Calle y número int/ext"
                                            id="addressInput"
                                            name="addressInput"
                                            onChange={(e) => inputChange(e)}
                                            InputLabelProps={{
                                                shrink: addressShrink,
                                            }}
                                            className="m-tb-20 form-control data-input"
                                            sx={{
                                                mb: 2,
                                                "& .MuiOutlinedInput-root:hover":
                                                    {
                                                        "& > fieldset": {
                                                            borderColor:
                                                                "#6C63FF",
                                                        },
                                                    },
                                            }}
                                        />
                                        <Grid
                                            item
                                            xs={12}
                                            className={"lead-form-input-error"}
                                        >
                                            <span>
                                                {errors?.addressInput && (
                                                    <p className="required-incidence m-0">
                                                        {" "}
                                                        {
                                                            errors?.addressInput
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </span>
                                        </Grid>
                                        <TextField
                                            {...register(
                                                "referenceInput",
                                                registerOptions?.referenceInput
                                            )}
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Referencia de dirección"
                                            placeholder="Referencia de dirección"
                                            id="referenceInput"
                                            name="referenceInput"
                                            onChange={(e) => inputChange(e)}
                                            InputLabelProps={{
                                                shrink: referenceInput,
                                            }}
                                            className="m-tb-20 form-control data-input"
                                            sx={{
                                                mb: 2,
                                                "& .MuiOutlinedInput-root:hover":
                                                    {
                                                        "& > fieldset": {
                                                            borderColor:
                                                                "#6C63FF",
                                                        },
                                                    },
                                            }}
                                        />
                                        <Grid
                                            item
                                            xs={12}
                                            className={"lead-form-input-error"}
                                        >
                                            <span>
                                                {errors?.referenceInput && (
                                                    <p className="required-incidence m-0">
                                                        {" "}
                                                        {
                                                            errors
                                                                ?.referenceInput
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </span>
                                        </Grid>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Número de contacto"
                                            placeholder="Número de contacto"
                                            id="contactPhoneInput"
                                            name="contactPhoneInput"
                                            {...register(
                                                "contactPhoneInput",
                                                registerOptions?.contactPhoneInput
                                            )}
                                            onChange={(e) => inputChange(e)}
                                            InputLabelProps={{
                                                shrink: contactPhoneShrink,
                                            }}
                                            className="m-tb-20 form-control data-input"
                                            sx={{
                                                mb: 2,
                                                "& .MuiOutlinedInput-root:hover":
                                                    {
                                                        "& > fieldset": {
                                                            borderColor:
                                                                "#6C63FF",
                                                        },
                                                    },
                                            }}
                                        />
                                        <Grid
                                            item
                                            xs={12}
                                            className={"lead-form-input-error"}
                                        >
                                            <span>
                                                {errors?.contactPhoneInput && (
                                                    <p className="required-incidence m-0">
                                                        {" "}
                                                        {
                                                            errors
                                                                ?.contactPhoneInput
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </span>
                                        </Grid>
                                    </>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Cambiar
                                </Button>
                                <Button onClick={handleCloseDialog}>
                                    Cancelar
                                </Button>
                            </form>
                        </DialogContent>
                    </Dialog>
                )}
                {isCustomDialogOpen && (
                    <CustomDialog
                        message={dialogMessage}
                        onClose={() => {
                            setIsCustomDialogOpen(false);
                            getSaleOrderDetailAction(simOrderId);
                        }}
                        open={isCustomDialogOpen}
                    />
                )}
            </Grid>
            <Dialog open={openActionFraud} onClose={handleClose}>
                <DialogTitle>
                    {"¿Estás seguro de que quieres ejecutar esta acción?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            "Una vez que ejecutes esta acción, no podrás deshacerla."
                        }
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Descripción"
                        type="text"
                        fullWidth
                        onChange={(event) => {
                            setDescriptionTransaction(event.target.value);
                            setError(event.target.value.length < 5);
                        }}
                        error={error}
                        helperText={
                            error
                                ? "Este campo requiere al menos 5 caracteres"
                                : ""
                        }
                    />
                    {loadingFraudAndChargeback && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                    {dialogMessage && (
                        <DialogContentText
                            style={{ color: isError ? "red" : "green" }}
                        >
                            {dialogMessage}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cerrar
                    </Button>

                    {(detailTransactionChargeback?.status !== 200 ||
                        detailTransactionFraud?.status !== 200) && (
                        <Button
                            onClick={handleConfirm}
                            color="primary"
                            autoFocus
                            disabled={error}
                        >
                            Aceptar
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    saleOrderDetail: state?.saleOrderDetail,
    saleOrderEsimQr: state?.saleOrderEsimQr,
    municipality: state?.municipality,
    neighborhood: state?.neighborhood,
    changeEsimToSim: state?.changeEsimToSim,
    changeSimToEsim: state?.changeSimToEsim,
    simActivation: state?.simActivation,
    detailTransactionFraud: state?.detailTransactionFraud,
    detailTransactionChargeback: state?.detailTransactionChargeback,
});

const mapDispatchToProps = {
    getEsimQr,
    municipalityAction,
    neighborhoodAction,
    getSaleOrderDetailAction,
    cleanChangeSimToEsimAction,
    cleanChangeEsimToSimAction,
    changeSimToEsimAction,
    changeEsimToSimAction,
    simActivationAction,
    postTransactionsChargeback,
    postTransactionsFraud,
    cleanTransactionChargeback,
    cleanTransactionFraud,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataTableDetailOrder);
