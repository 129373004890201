import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import { createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";

const theme = createTheme({
    palette: {
        primary: {
            main: red[500],
        },
        secondary: {
            main: yellow[700],
        },
        text: {
            default: "#000000",
        },
    },
});

export const PageTokuHook = ({
    listTokuAction,
    cleanTransactionsTokuClean,
    detailsTransactionsToku,
    listTransactionsToku,
    detailTransactionTokuAction,
    cleanDetailTokuAction,
}) => {
    const { payload } = listTransactionsToku || {};
    const { response } = detailsTransactionsToku || {};
    const { rows, pagination } = payload || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (response?.response?.status > 200) {
            setDialogMessage("Error");
            setDialogMessage(response?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        // Verificar si searchParams tiene algún valor
        if (Object.keys(searchParams).length !== 0) {
            setLoading(true);
            listTokuAction(page, size, searchParams)
                .then(() => setLoading(false))
                .catch((error) => {
                    setLoading(false);
                });
        }
        return () => {
            cleanTransactionsTokuClean();
        };
    }, [page, size, searchParams, listTokuAction, cleanTransactionsTokuClean]);

    useEffect(() => {
        if (listTransactionsToku?.status === 200) {
            setLoading(false);
        } else if (listTransactionsToku?.payload?.response?.status > 200) {
            setDialogMessage(
                listTransactionsToku?.payload?.response?.data?.detail
            );
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [listTransactionsToku]);

    const onSubmit = (data) => {
        const { from_date, to_date, beneficiary_account, reference } = data;

        const areDatesProvided = from_date !== "" && to_date !== "";
        const isAuthorizationOrCardProvided =
            beneficiary_account !== "" || reference !== "";

        if (areDatesProvided || isAuthorizationOrCardProvided) {
            setLoading(true);
            setPage(1);
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(([_, value]) => value !== "")
            );
            setSearchParams(filteredData);
            setErrorMessage("");
        } else {
            setErrorMessage(
                "Por favor, complete al menos uno de los siguientes campos: Referencia, Clave STP o ambas fechas."
            );
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    const getBackgroundColor = (row) => {
        if (row.is_chargeback) {
            return theme.palette.secondary.main;
        } else if (row.is_fraud) {
            return theme.palette.warning.main;
        } else {
            return theme.palette.background.default;
        }
    };

    const getTextColor = (row) => {
        if (row.is_chargeback || row.is_fraud) {
            return "#ffffff";
        } else {
            return theme.palette.text.default;
        }
    };

    const mappedRows =
        rows?.map((row) => ({
            ...row,
            id: `${row.id}`,
            backgroundColor: getBackgroundColor(row),
        })) || [];

    const handleIdButtonClick = (id) => {
        setLoading(true);
        if (id) {
            setIsDialogOpenDetails(true);
            detailTransactionTokuAction(id)
                .then(() => setLoading(false))
                .catch((error) => {
                    setDialogMessage("Error");
                    setDialogMessage(
                        "Error al cargar los detalles de la transferencia."
                    );
                    setIsDialogOpen(true);
                    setLoading(false);
                });

            return () => {
                cleanDetailTokuAction();
            };
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setIsDialogOpenDetails(false);
        cleanDetailTokuAction();
    };

    const Columns = [
        {
            field: "id",
            headerName: "ID",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleIdButtonClick(params.row.id)}
                >
                    {"Ver detalle"}
                </Button>
            ),
        },
        {
            field: "created",
            headerName: "Fecha",
            width: 250,
            headerAlign: "center",
            renderCell: (params) => (
                <div style={{ backgroundColor: params.row.backgroundColor }}>
                    {convertToMexicoTime(params.row.toku_transaction_date)}
                </div>
            ),
        },
        {
            field: "customer_name",
            headerName: "Nombre del cliente",
            width: 220,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <div style={{ backgroundColor: params.row.backgroundColor }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: "gateway",
            headerName: "Gateway",
            width: 200,
            headerAlign: "center",
            renderCell: (params) => (
                <div style={{ backgroundColor: params.row.backgroundColor }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: "payment_method",
            headerName: "Método de pago",
            width: 200,
            align: "right",
            headerAlign: "center",
            renderCell: (params) => (
                <div style={{ backgroundColor: params.row.backgroundColor }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: "payment_status",
            headerName: "Estado de pago",
            width: 200,
            align: "right",
            headerAlign: "center",
            renderCell: (params) => (
                <div style={{ backgroundColor: params.row.backgroundColor }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: "amount",
            headerName: "Cantidad",
            width: 200,
            align: "right",
            headerAlign: "center",
            valueFormatter: (params) => {
                const formatter = new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 2,
                });
                return formatter.format(params.value);
            },
            renderCell: (params) => (
                <div style={{ backgroundColor: params.row.backgroundColor }}>
                    {params.formattedValue}
                </div>
            ),
        },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        selectedTransactionId,
        errorMessage,
        setErrorMessage,
        getBackgroundColor,
        getTextColor,
    };
};
