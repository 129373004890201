import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const LineEventsHook = (props) => {
    const [modalOpenSim, setModalOpenSim] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [imeiNumber, setImeiNumber] = useState("");
    const [valueAgent, setValueAgent] = useState();
    const [agentList, setAgentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ticketId, setTicketId] = useState();
    const [directNumber, setDirectNumber] = useState("");
    const [loader, setLoader] = useState(false);
    const [statusProfile, setStatusProfile] = useState("");

    let params = useParams();

    const handleChange = (event) => {
        setValueAgent(event.target.value);
        props.changeUser({
            ticketId: ticketId,
            id: event.target.value,
        });
    };

    useEffect(() => {
        setTicketId(params.ticketId);
        props.getUsers();
    }, []);

    useEffect(() => {
        const { events } = props.events || { events: undefined };

        if (Array.isArray(events.events)) {
            setEventsList(events.events);
            setLoader(false);
        }
    }, [props.events]);

    useEffect(() => {
        const { users } = props.userList.response || {
            users: undefined,
        };
        if (typeof users != "undefined") {
            setAgentList(users);
        }
    }, [props.userList]);

    useEffect(() => {
        const { customerProfile } = props.customerProfile || {
            customerProfile: undefined,
        };

        if (typeof customerProfile != "undefined") {
            setDirectNumber(customerProfile.profile.direct_number);
            setImeiNumber(customerProfile.profile.imei);
            setStatusProfile(customerProfile.status.subStatus);
        }
    }, [props.customerProfile]);

    useEffect(() => {
        props.getEvents({ ticket_id: params.ticketId });
        setLoader(true);
    }, [props.changeUser_]);

    useEffect(() => {
        const response = props.ticketById.response || undefined;
        if (typeof response != "undefined") {
            setIsLoading(false);
            setValueAgent(response.assigned_to_user_id);
            // setDirectNumber(response.direct_number);
            // // setTicketById(response.id);
            // setName(response.created_by_user_name);
            // setLastName(response.created_by_user_last_name);
            // setMothersLastName(response.created_by_user_mothers_last_name);
        }
    }, [props.ticketById]);

    return {
        modalOpenSim,
        setModalOpenSim,
        eventsList,
        setEventsList,
        imeiNumber,
        setImeiNumber,
        valueAgent,
        setValueAgent,
        agentList,
        setAgentList,
        isLoading,
        setIsLoading,
        ticketId,
        setTicketId,
        directNumber,
        setDirectNumber,
        loader,
        setLoader,
        statusProfile,
        setStatusProfile,
        handleChange,
        params,
    };
};
