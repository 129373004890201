import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const DataGridHook = (props) => {
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const { state } = useLocation();

    const { getMyTickets, ticketsReducer, cleanTicketsAction } = props || {};

    useEffect(() => {
        getMyTickets();
        setLoader(true);
        return () => {
            cleanTicketsAction();
        };
    }, []);

    useEffect(() => {
        generateRows();
    }, [ticketsReducer]);

    const generateRows = () => {
        const { loaded, method, payload, state, status } = ticketsReducer || {};
        if (loaded) {
            const { pagination, tickets } = payload || {};
            setLoader(false);

            setRows(tickets);
        }
    };

    const handleNavigate = (params) => {
        if (
            typeof params?.target?.id != "undefined" &&
            typeof params?.target?.name != "undefined"
        ) {
            navigate("/tickets/" + params.target.id, {
                state: { directNumber: params.target.name },
            });
        }
    };
    return {
        handleNavigate,
        generateRows,
        rows,
        loader,
    };
};
