import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import moment from "moment";

import { styled } from "@mui/material/styles";

import { Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import CustomLinearProgress from "widgets/datagrid/CustomLinearProgress";
import CustomNoRowsOverlay from "widgets/datagrid/CustomNoRowsOverlay";

import { LoadersContext } from "_models/context/app";

import { setLeadAssignmentAction } from "_models/redux/lead-assignment/action";
import { cleanLeadAssignmentAction } from "_models/redux/lead-assignment/clean_action";
import { getLeads } from "_models/redux/leads/action";
import { cleanLeadsAction } from "_models/redux/leads/clean-action";

import CustomPagination from "./pagination";

import { LeadsListContext } from "../context";

const LeadsDatagrid = (props) => {
    const {
        reset,
        getValues,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};
    ////

    const userId = localStorage.getItem("user_id");

    ////

    const history = useNavigate();

    ////

    const datagridStyles = {
        "& .MuiDataGrid-columnHeaders": {
            position: "sticky",
            zIndex: "999",
            background: "white",
        },
        "& .MuiDataGrid-virtualScroller": {
            marginTop: "0!important",
        },

        "& .MuiDataGrid-main": {
            overflow: "hidden",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            width: "0.4em",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
            background: "#f1f1f1",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
            backgroundColor: "#E7DEE7",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
            background: "#DFD4DF",
        },
    };

    ////

    const {
        leadsObj,
        getLeads,
        leadAssignment,
        setLeadAssignmentAction,
        cleanLeadAssignmentAction,
    } = props || {};
    const { payload, state, status, loaded, method } = leadsObj || {};
    const { leads, pagination } = payload || {};
    const { pages, page, size } = pagination || {};

    const [rowCount, setRowCount] = useState(50);

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);

    const [loadingDatagrid, setLoadingDatagrid] = useState(true);

    const [datagridPageCounter, setDatagridPageCounter] = useState({
        dpCounter: 0,
        npSize: 50,
    });
    const [datagridPageSize, setDatagridPageSize] = useState(50);
    const [datagridPage, setDatagridPage] = useState(0);

    const [dateValue, setDateValue] = useState(null);

    useEffect(() => {
        if (dateValue) {
            const formatDateValue = moment(
                dateValue.format("YYYY-MM-DD")
            ).format();
        }
    }, [dateValue]);

    useEffect(() => {
        const { id, message } = leadAssignment?.payload || {};

        if (message === "success" && id) {
            const link = "/lead-detail/" + id;
            history(link);
            setOpen(false);
        } else if (
            leadAssignment?.state === "error" &&
            (leadAssignment?.status === null || leadAssignment?.status >= 300)
        ) {
            setOpen(true);
        }
        setIsScreenLoading(false);
    }, [leadAssignment]);

    useEffect(() => {
        getLeadsMethod("ue[datagridPage]");
    }, [datagridPage]);

    useEffect(() => {
        if (datagridPage == 0 && datagridPageCounter?.dpCounter > 0) {
            getLeadsMethod("[datagridPageSize]");
        } else {
            setDatagridPage(0);
        }
    }, [datagridPageSize]);

    const getLeadsMethod = (from) => {
        setLoadingDatagrid(true);
        setRows([]);

        const filtersValues = getValues();

        const { nameInput, emailInput, phoneInput } = filtersValues || {};

        let formatDateValue = "";

        if (dateValue) {
            formatDateValue = moment(dateValue.format("YYYY-MM-DD")).format();
        }

        let dateFormatted = "";
        if (formatDateValue.split("T")[0]) {
            dateFormatted = formatDateValue.split("T")[0];
        }

        if (from === "search") {
            setDatagridPage(0);
        }

        getLeads({
            page: from === "search" ? 0 : datagridPage,
            size: datagridPageSize,
            contact_name: nameInput,
            contact_phone: phoneInput,
            contact_email: emailInput,
            date: dateFormatted,
        });
    };

    const changePage = (newPage) => {
        setDatagridPage(newPage);
    };

    const changePageSize = (newPageSize) => {
        setDatagridPageCounter({
            dpCounter: datagridPageCounter?.dpCounter + 1,
            npSize: newPageSize,
        });
    };

    useEffect(() => {
        const { dpCounter, npSize } = datagridPageCounter || {};

        if (dpCounter > 0) {
            setDatagridPageSize(npSize);
        }
    }, [datagridPageCounter]);

    useEffect(() => {
        generateDataColumns();

        return () => {
            if (typeof cleanLeadsAction == "function") cleanLeadsAction();

            if (typeof cleanLeadAssignmentAction == "function")
                cleanLeadAssignmentAction();
        };
    }, []);

    useEffect(() => {
        if (rows?.length > 0) setLoadingDatagrid(false);
    }, [rows]);

    useEffect(() => {
        generateDataRows();
    }, [leads]);

    useEffect(() => {
        setLoadingDatagrid(false);
        if (state === "success" && (status >= 200 || status < 300)) {
            setOpen(false);
        } else if (state === "error" && (status >= 300 || status === null)) {
            setOpen(true);
        }
    }, [leadsObj]);

    useEffect(() => {
        const { pages, total_rows } = pagination || {};

        if (parseInt(pages) > 0) {
            setRowCount(total_rows);
        }
    }, [pagination]);

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));

    const goToLink = (leadId) => {
        if (leadId) {
            setIsScreenLoading(true);
            setLeadAssignmentAction({ lead_id: leadId });
        }
    };

    const generateDataColumns = () => {
        let leadsColumns = [
            {
                field: "id",
                headerName: "Lead ID",
                width: 200,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { id, backoffice_user_id } = row || {};

                    return parseInt(userId) === parseInt(backoffice_user_id) ||
                        backoffice_user_id === null ? (
                        <div
                            className={
                                parseInt(userId) ===
                                parseInt(backoffice_user_id)
                                    ? "selected"
                                    : ""
                            }
                        >
                            <span
                                onClick={() => goToLink(id)}
                                className={"lead-detail-link"}
                            >
                                {id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <span
                                className={"lead-detail-link assigned-to-other"}
                            >
                                {id}
                            </span>
                        </div>
                    );
                },
            },
            {
                field: "status_backoffice",
                headerName: "Back Office Estatus",
                width: 310,
            },
            {
                field: "contact_name",
                headerName: "Nombre de contacto",
                width: 310,
            },
            {
                field: "creationTime",
                headerName: "Fecha de creación",
                width: 310,
            },
            {
                field: "updatedTime",
                headerName: "Fecha de actualización",
                width: 310,
            },
        ];

        setColumns(leadsColumns);
    };

    const generateDataRows = () => {
        let leadsRows = [];

        if (leads != undefined) {
        }

        leads?.map((element, index) => {
            leadsRows.push({
                id: element?.id,
                updatedTime: element?.updated
                    ? moment(parseInt(element.updated) * 1000).format(
                          "DD/MM/YYYY HH:mm:ss"
                      )
                    : "",
                creationTime: element?.created
                    ? moment(parseInt(element.created) * 1000).format(
                          "DD/MM/YYYY HH:mm:ss"
                      )
                    : "",
                contact_name: element?.contact_name,
                status_backoffice: element?.lead_backoffice_status,
                backoffice_user_id: element?.backoffice_user_id,
            });
        });

        setRows(leadsRows);
    };

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    const handleClose = () => {
        setOpen(false);
        setIsScreenLoading(false);
    };

    const PopModalError = () => {
        return (
            <div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Error
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {" --- "}
                            {leadAssignment?.payload?.code ||
                            leadAssignment?.payload?.response?.data?.detail ||
                            leadsObj?.payload?.response?.data?.detail ||
                            leadsObj?.payload?.code ? (
                                <>
                                    <p>
                                        {
                                            leadAssignment?.payload?.response
                                                ?.data?.detail
                                        }
                                    </p>
                                    <p>
                                        {leadAssignment?.payload?.message
                                            ? "[" +
                                              leadAssignment?.payload?.message +
                                              "]"
                                            : ""}
                                    </p>

                                    <p>
                                        {
                                            leadsObj?.payload?.response?.data
                                                ?.detail
                                        }
                                    </p>
                                    <p>
                                        {leadsObj?.payload?.message
                                            ? "[" +
                                              leadsObj?.payload?.message +
                                              "]"
                                            : ""}
                                    </p>
                                </>
                            ) : null}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        );
    };

    const registerOptions = {
        nameInput: {
            minLength: {
                value: 3,
                message: "Mínimo 3 caracteres",
            },
            maxLength: {
                value: 150,
                message: "Máximo 150 caracteres",
            },
        },

        phoneInput: {
            pattern: {
                value: /^(0|[0-9]\d*)(\.\d+)?$/,
                message: "El formato telefónico no es correcto",
            },
        },

        emailInput: {
            pattern: {
                value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/,
                message:
                    "El valor ingresado no coincide con el formato del correo electrónico",
            },
        },
    };

    const inputChange = (e) => {};
    const [nameShrink, setNameShrink] = useState();
    const [phoneShrink, setPhoneShrink] = useState();
    const [emailShrink, setEmailShrink] = useState();

    const onSubmit = (e) => {
        getLeadsMethod("search");
    };

    const cleanAll = () => {
        setDateValue(null);
        reset({
            nameInput: "",
            phoneInput: "",
            emailInput: "",
        });
    };

    const [myLeadsListPage, setMyLeadsListPage] = useState(0);

    const myLeadsListValues = {
        page: datagridPage,
        pageCount: rowCount,
        paginationState: {
            paginationModel: {
                page: 0,
                pages: pages,
                pageSize: datagridPageSize,
                rowCount: rowCount,
            },
        },
        myLeadsListPage,
        setMyLeadsListPage,
        datagridPageSize,
        setDatagridPageSize,
        datagridPage,
        setDatagridPage,
        changePage,
        changePageSize,
    };

    return (
        <LeadsListContext.Provider value={myLeadsListValues}>
            <Grid container>
                <Grid item xs={12} className={"filters-container"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid
                                item
                                xs={6}
                                md={2}
                                className={"filter-container"}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale={"es"}
                                >
                                    <DatePicker
                                        value={dateValue}
                                        onChange={(newValue) => {
                                            setDateValue(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={3}
                                className={"filter-container"}
                            >
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Nombre"
                                    placeholder="Nombre"
                                    id="nameInput"
                                    name="nameInput"
                                    {...register(
                                        "nameInput",
                                        registerOptions?.nameInput
                                    )}
                                    onChange={(e) => inputChange(e)}
                                    InputLabelProps={{
                                        shrink: nameShrink,
                                    }}
                                    className="m-tb-20 form-control data-input"
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#6C63FF",
                                            },
                                        },
                                    }}
                                />

                                {errors?.nameInput && (
                                    <span>
                                        <p className="required-incidence">
                                            {" "}
                                            {errors?.nameInput?.message}
                                        </p>
                                    </span>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={3}
                                className={"filter-container"}
                            >
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Número telefónico"
                                    placeholder="Número telefónico"
                                    id="phoneInput"
                                    name="phoneInput"
                                    {...register(
                                        "phoneInput",
                                        registerOptions?.phoneInput
                                    )}
                                    onChange={(e) => inputChange(e)}
                                    InputLabelProps={{
                                        shrink: phoneShrink,
                                    }}
                                    className="m-tb-20 form-control data-input"
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#6C63FF",
                                            },
                                        },
                                    }}
                                />

                                {errors?.phoneInput && (
                                    <span>
                                        <p className="required-incidence">
                                            {" "}
                                            {errors?.phoneInput?.message}
                                        </p>
                                    </span>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={3}
                                className={"filter-container"}
                            >
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Correo electrónico"
                                    placeholder="Correo electrónico"
                                    id="emailInput"
                                    name="emailInput"
                                    {...register(
                                        "emailInput",
                                        registerOptions?.emailInput
                                    )}
                                    onChange={(e) => inputChange(e)}
                                    InputLabelProps={{
                                        shrink: emailShrink,
                                    }}
                                    className="m-tb-20 form-control data-input"
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#6C63FF",
                                            },
                                        },
                                    }}
                                />

                                {errors?.emailInput && (
                                    <span>
                                        <p className="required-incidence">
                                            {" "}
                                            {errors?.emailInput?.message}
                                        </p>
                                    </span>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={2}
                                className={"filter-container"}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => setDateValue(null)}
                                >
                                    Limpiar fecha
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={2}
                                className={"filter-container"}
                            >
                                <Button variant="outlined" onClick={cleanAll}>
                                    Limpiar todo
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={2}
                                className={"filter-container"}
                            >
                                <Button variant="outlined" type="submit">
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>

                <div
                    style={{ height: "auto", width: "100%" }}
                    className={"leads-list"}
                >
                    <DataGrid
                        autoHeight={true}
                        columns={columns}
                        rows={rows}
                        pagination
                        paginationMode="server"
                        onPageChange={(newPage) => changePage(newPage)}
                        onPageSizeChange={(newPageSize) =>
                            changePageSize(newPageSize)
                        }
                        rowsPerPageOptions={[50, 75, 100]}
                        pageSize={datagridPageSize}
                        page={datagridPage}
                        rowCount={rowCount}
                        loading={loadingDatagrid}
                        disableColumnMenu
                        disableSelectionOnClick={true}
                        checkboxSelection={false}
                        sx={datagridStyles}
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            NoResultsOverlay: CustomNoRowsOverlay,
                            LoadingOverlay: CustomLinearProgress,
                            Pagination: CustomPagination,
                        }}
                    />
                    <PopModalError />
                </div>
            </Grid>
        </LeadsListContext.Provider>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    leadsObj: state?.leads,
    leadAssignment: state?.leadAssignment,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getLeads,
    cleanLeadsAction,
    setLeadAssignmentAction,
    cleanLeadAssignmentAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(LeadsDatagrid);
