import React, { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";

export const ApplyPackageHook = (props) => {
    const { cleanPortabilityAction, portability } = props;
    const { loaded, payload, status, state } = portability || {};
    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    const [success, setSuccess] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loaderButton, setLoaderButton] = useState(false);
    const [response, setResponse] = useState("");
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);

    const {
        handleSubmit,
        control,
        register,
        setValue,
        watch,
        reset,
        getValues,
        formState: { errors },
    } = useForm();

    const onSubmit = (formData) => {};

    const handleSuccessDialogClose = () => {
        setSuccessDialogOpen(false);
    };

    const handleClickButton = () => {
        setIsLoading(true);
        setIsDialogOpen(true);
    };

    useEffect(() => {
        cleanPortabilityAction();
        return () => {
            cleanPortabilityAction();
        };
    }, []);

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const { message, additional_details, context, detail, title, type } =
            payload || {};

        if (loaderButton && loaded) {
            setResponse(
                <Grid container className={"m-t-15 response-container"}>
                    <Grid item xs={12} className={"response-title"}>
                        {title || ""}
                    </Grid>
                    <Grid item xs={12} className={"response-text"}>
                        {detail || message || ""}
                    </Grid>
                </Grid>
            );
            setLoaderButton(false);
        }
    }, [portability]);

    useEffect(() => {
        if (isDialogOpen) {
            setIsLoading(false);
        }
    }, [isDialogOpen]);

    const myWrapper = (myComponents) => {
        return <form onSubmit={handleSubmit(onSubmit)}>{myComponents} </form>;
    };

    const handleAccept = () => {
        // Después de aplicar el paquete con éxito, abrir el diálogo de éxito
        setIsDialogOpen(false); // Cerrar el diálogo principal
        setSuccessDialogOpen(true);
    };

    return {
        myWrapper,
        successDialogOpen,
        handleSuccessDialogClose,
        success,
        isDialogOpen,
        setIsDialogOpen,
        handleAccept,
        control,
        register,
        setValue,
        watch,
        reset,
        getValues,
        errors,
    };
};
