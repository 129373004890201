import React from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { getMyTickets } from "_models/redux/my-tickets/action";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { getUsers } from "_models/redux/get-user-list/action";
import { changeUser } from "_models/redux/change-user/action";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import { rechargeHistoryAction } from "_models/redux/recharge-history/action";
import { rechargeHistoryClean } from "_models/redux/recharge-history/clean-action";
import AgentAssignment from "./AgentAssignment";
import RechargeHistoryButton from "./RechargeHistoryButton";
import MakePrepaid from "./MakePrepaid.jsx";
import SimActions from "./SimActions";
import ActionImei from "./actions-tickets/actionImei";
import SupplementaryService from "./actions-tickets/actionSupplementaryService";
import ActionSendApn from "./actions-tickets/actionSendApn";
import LockImeiContainer from "./actions-tickets/actionLockImei";
import UnlockImeiContainer from "./actions-tickets/actionUnlockImei";
import ActionBarring from "./actions-tickets/actionBarring";
import ActionUnbarring from "./actions-tickets/actionUnbarring";
import ActionSuspendLine from "./actions-tickets/actionSuspendLine";
import ResumeLine from "./actions-tickets/actionResumeLine";
import ChangeNir from "./actions-tickets/ActionChangeNir";
import ActionPortability from "./actions-tickets/actionPortability";
import ActionApplyPackage from "modules/support/my-tickets/flow/detailTickets/components/actions-tickets/applyPackage";
import CustomDialog from "widgets/component/CustomDialog";
import ContentRechargeHistory from "./contentRechargeHistory";
import { ActionBarHook } from "../hooks/actionBarHook";
import { getLoginDataHelper } from "_controllers/helpers/getLoginData";

const ActionsBar = (props) => {
    const { rechargeHistoryAction, rechargeHistory, rechargeHistoryClean } =
        props || {};

    const {
        handleChange,
        valueAgent,
        agentList,
        directNumber,
        imeiNumber,
        statusProfile,
        setisDialogOpenRechargeHistory,
        ticketId,
        setModalOpenSim,
        modalOpenSim,
        isDialogOpenRechargeHistory,
        dialogMessage,
        setIsDialogOpen,
        isDialogOpen,
    } = ActionBarHook(props);

    const { userPermissions } = getLoginDataHelper();
    const permissionsArray = userPermissions.split(",");
    const canSeeComponent = permissionsArray.includes(
        "SUPPORT_TICKETS_SUPERVISOR"
    );
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                className="button-box"
                textAlign={"start"}
                paddingLeft={2}
                paddingRight={2}
                direction="column"
            >
                <AgentAssignment
                    handleChange={handleChange}
                    valueAgent={valueAgent}
                    agentList={agentList}
                />
                <RechargeHistoryButton
                    onClick={() => setisDialogOpenRechargeHistory(true)}
                />{" "}
                {canSeeComponent && <MakePrepaid ticketId={ticketId} />}
                <ActionImei directNumber={directNumber} ticketId={ticketId} />
                <SimActions
                    setModalOpenSim={setModalOpenSim}
                    modalOpenSim={modalOpenSim}
                    ticketId={ticketId}
                />
                <SupplementaryService
                    directNumber={directNumber}
                    ticketId={ticketId}
                />
                <ActionSendApn
                    directNumber={directNumber}
                    ticketId={ticketId}
                />
                <LockImeiContainer
                    directNumber={directNumber}
                    ticketId={ticketId}
                    imeiNumber={imeiNumber}
                />
                <UnlockImeiContainer
                    ticketId={ticketId}
                    imeiNumber={imeiNumber}
                />
                {statusProfile === "Active" && (
                    <ActionBarring ticketId={ticketId} />
                )}
                {statusProfile === "Barring (B1W) (Notified by client)" && (
                    <ActionUnbarring ticketId={ticketId} />
                )}
                {statusProfile === "Active" && (
                    <ActionSuspendLine
                        ticketId={ticketId}
                        directNumber={directNumber}
                    />
                )}
                {statusProfile === "Suspend (B2W)" && (
                    <ResumeLine
                        ticketId={ticketId}
                        directNumber={directNumber}
                    />
                )}
                <ChangeNir ticketId={ticketId} directNumber={directNumber} />
                <ActionPortability
                    ticketId={ticketId}
                    directNumber={directNumber}
                />
                <ActionApplyPackage
                    ticketId={ticketId}
                    directNumber={directNumber}
                />
            </Grid>

            <CustomDialog
                title="Historial de recargas"
                content={
                    <ContentRechargeHistory
                        rechargeHistoryAction={rechargeHistoryAction}
                        ticketId={ticketId}
                        rechargeHistory={rechargeHistory}
                        rechargeHistoryClean={rechargeHistoryClean}
                    />
                }
                onClose={() => setisDialogOpenRechargeHistory(false)}
                open={isDialogOpenRechargeHistory}
                fullWidth
                maxWidth=""
                showCloseButton={false}
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: () => setisDialogOpenRechargeHistory(false),
                        buttonColor: "#724ECB",
                        textColor: "white",
                    },
                ]}
            />

            <CustomDialog
                title="Cambio de Usuario"
                content={dialogMessage}
                onClose={() => setIsDialogOpen(false)}
                open={isDialogOpen}
                fullWidth
                maxWidth="xs"
                showCloseButton={false}
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: () => setIsDialogOpen(false),
                        buttonColor: "#724ECB",
                        textColor: "white",
                    },
                ]}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    tickets: state.tickets,
    events: state.events,
    customerProfile: state.customerProfile,
    userList: state.userList,
    changeUser_: state.changeUser_,
    ticketById: state.ticketById,
    rechargeHistory: state.rechargeHistory,
});

const mapDispatchToProps = {
    getMyTickets,
    getCustomerProfile,
    getUsers,
    changeUser,
    getTicketById,
    rechargeHistoryAction,
    rechargeHistoryClean,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsBar);
