import React, { useState, useEffect } from "react";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import { useForm } from "react-hook-form";
import { Chip } from "@mui/material";

export const ContentRechargeHistoryHooks = ({
    ticketId,
    rechargeHistoryAction,
    rechargeHistory,
    rechargeHistoryClean,
}) => {
    const { response } = rechargeHistory || {};
    const { pagination, result } = response || {};

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDialogOpenResponse, setIsDialogOpenResponse] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (dates) => {
        setLoading(true);
        const payload = {
            dates,
            ticketId,
        };
        rechargeHistoryAction(payload);
    };

    const mappedRows =
        result?.map((row) => ({
            ...row,
            id: `${row.id}`,
        })) || [];

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const getStatusColor = (paymentStatus) => {
        switch (paymentStatus) {
            case "SUCCESS":
                return "green";
            case "FAIL":
                return "red";
            case "Review":
                return "Orange";
            default:
                return "purple";
        }
    };

    const translationMap = {
        Requested: "Transacción registrada",
        SUCCESS: "Transacción exitosa",
        FAIL: "Falló al procesar el pago",
        Review: "En espera de revisión",
        ERROR: "Error al procesar el pago",
    };

    const Columns = [
        { field: "id", headerName: "ID", width: 90, headerAlign: "center" },
        { field: "direct_number", headerName: "Télefono", width: 150 },
        {
            field: "product",
            headerName: "Paquete",
            width: 200,
        },
        {
            field: "created",
            headerName: "Fecha de creación",
            width: 200,

            headerAlign: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },
        {
            field: "offer_expires_at",
            headerName: "Fecha de vencimiento",
            width: 200,
            headerAlign: "center",
            valueGetter: (params) => {
                const date = params.row.offer_expires_at;
                return date ? convertToMexicoTime(date) : "Sin fecha";
            },
        },
        {
            field: "status",
            headerName: "Estado de pago",
            width: 220,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Chip
                    label={
                        translationMap[params.row.status] || params.row.status
                    }
                    style={{
                        backgroundColor: getStatusColor(params.row.status),
                        color: "white",
                    }}
                />
            ),
        },
        {
            field: "combined_distributor",
            headerName: "Distribuidor",
            width: 400,
            valueGetter: (params) => {
                const distributor = params.row.distributor || "";
                const distributorUser = params.row.distributor_user || "";
                return `${distributor} - ${distributorUser}`;
            },
        },
        {
            field: "custom_combined_message",
            headerName: "Mensaje",
            width: 500,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
                const responseMessage = params.row.response_message || "";
                const responseCode = params.row.response_code || "";
                return `${responseMessage}  ${responseCode}`;
            },
        },
        {
            field: "source",
            headerName: "Fuente",
            width: 200,
        },
    ];

    useEffect(() => {
        if (rechargeHistory?.status === 200) {
            setLoading(false);
        } else if (rechargeHistory?.response?.response?.status > 200) {
            setDialogMessage(rechargeHistory?.response?.response?.data?.detail);
            setIsDialogOpenResponse(true);
            setLoading(false);
        }
    }, [rechargeHistory]);

    const handleClose = () => {
        setIsDialogOpenResponse(false);
        rechargeHistoryClean();
    };

    return {
        Columns,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        onSubmit,
        mappedRows,
        register,
        handleSubmit,
        size,
        errors,
        page,
        isDialogOpenResponse,
        dialogMessage,
        setIsDialogOpenResponse,
        loading,
        handleClose,
    };
};
