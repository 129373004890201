import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { LoadersContext } from "_models/context/app";
import { useParams } from "react-router-dom";

export const ActionChangeNirHook = (props) => {
    let params = useParams();
    const { ticketId, changeNirReducer } = props;
    const { loading, changeNir, error } = changeNirReducer || "";

    const [modalOpen, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [stateValue, setStateValue] = useState();
    const [stateName, setStateName] = useState();
    const [nirValueSelect, setNirValueSelect] = useState();
    const [nirNumber, setNirNumber] = useState();
    const [disableSelectNir, setdisableSelectNir] = useState(true);
    const [nirsMap, setNirs] = useState([]);
    const [loaderButton, setLoaderButton] = useState(false);
    const [alert, setAlert] = useState(false);

    const [errorMssge, setErrorMssge] = useState("");

    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const { nir } = props.nir || {
            nirs: undefined,
            state_id: undefined,
        };
        const nirs = nir.nirs || undefined;

        if (Array.isArray(nirs)) {
            setNirs(nirs);
        }
    }, [props.nir]);

    useEffect(() => {
        let statusMessage = props.changeNirReducer?.changeNir?.status;
        let detailMessageError =
            props.changeNirReducer?.error?.response?.data?.detail;

        if (statusMessage == 200) {
            setSeverityAlert("success");
            setErrorMssge("El cambió de NIR se realizó satisfactoriamente");
            setAlert(true);
            setLoaderButton(false);
            props.getEvents({ ticket_id: params.ticketId });
        } else {
            setSeverityAlert("warning");
            setErrorMssge(detailMessageError);
            setAlert(true);
            setLoaderButton(false);
        }
    }, [props.changeNirReducer]);

    const sepomex = {
        states: [
            {
                id: 1,
                estado: "Aguascalientes",
            },
            {
                id: 2,
                estado: "Baja California",
            },
            {
                id: 3,
                estado: "Baja California Sur",
            },
            {
                id: 4,
                estado: "Campeche",
            },
            {
                id: 5,
                estado: "Coahuila de Zaragoza",
            },
            {
                id: 6,
                estado: "Colima",
            },
            {
                id: 7,
                estado: "Chiapas",
            },
            {
                id: 8,
                estado: "Chihuahua",
            },
            {
                id: 9,
                estado: "Ciudad de México",
            },
            {
                id: 10,
                estado: "Durango",
            },
            {
                id: 11,
                estado: "Guanajuato",
            },
            {
                id: 12,
                estado: "Guerrero",
            },
            {
                id: 13,
                estado: "Hidalgo",
            },
            {
                id: 14,
                estado: "Jalisco",
            },
            {
                id: 15,
                estado: "México",
            },
            {
                id: 16,
                estado: "Michoacán de Ocampo",
            },
            {
                id: 17,
                estado: "Morelos",
            },
            {
                id: 18,
                estado: "Nayarit",
            },
            {
                id: 19,
                estado: "Nuevo León",
            },
            {
                id: 20,
                estado: "Oaxaca",
            },
            {
                id: 21,
                estado: "Puebla",
            },
            {
                id: 22,
                estado: "Querétaro",
            },
            {
                id: 23,
                estado: "Quintana Roo",
            },
            {
                id: 24,
                estado: "San Luis Potosí",
            },
            {
                id: 25,
                estado: "Sinaloa",
            },
            {
                id: 26,
                estado: "Sonora",
            },
            {
                id: 27,
                estado: "Tabasco",
            },
            {
                id: 28,
                estado: "Tamaulipas",
            },
            {
                id: 29,
                estado: "Tlaxcala",
            },
            {
                id: 30,
                estado: "Veracruz de Ignacio de la Llave",
            },
            {
                id: 31,
                estado: "Yucatán",
            },
            {
                id: 32,
                estado: "Zacatecas",
            },
        ],
    };

    const {
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (e) => {
        const post = {
            ticket_id: ticketId,
            nirNumber: nirNumber,
        };

        props.postChangeNir(post);
        setLoaderButton(true);
    };

    const handleClickButton = () => {
        setIsLoading(true);
        setOpenModal(true);
    };

    useEffect(() => {
        if (modalOpen) {
            setIsLoading(false);
            props.cleanChangeNir();
            props.cleanNirs();
        }
    }, [modalOpen]);

    const handleCloseClean = () => {
        setStateValue();
        setNirValueSelect();
        setNirNumber();
        setdisableSelectNir(true);
    };

    const handleSelectState = (event) => {
        const stateValue = event.target.value || "";
        setStateValue(stateValue);
        setStateName(stateValue);
        setdisableSelectNir(false);
        props.getNir({ state_id: stateValue });
    };

    const handleSelectNIR = (event) => {
        setNirValueSelect(event?.target?.value);

        let nirNumberValue = event?.target?.value?.split("_");
        nirNumberValue =
            typeof nirNumberValue[1] != "undefined" ? nirNumberValue[1] : "";
        setNirNumber(nirNumberValue || "");
    };

    useEffect(() => {}, [nirNumber]);

    useEffect(() => {}, [nirValueSelect]);

    useEffect(() => {
        return () => {
            props.cleanNirs();
            props.cleanChangeNir();
            setStateValue();
            setNirValueSelect();
            setNirNumber();
            setdisableSelectNir(true);
            setErrorMssge();
        };
    }, [modalOpen]);

    const closePopup = () => {
        setOpenModal(false);
        setIsLoading(false);
    };
    return {
        onSubmit,
        handleClickButton,
        handleSelectState,
        handleSelectNIR,
        closePopup,
        stateValue,
        stateName,
        nirValueSelect,
        nirNumber,
        disableSelectNir,
        nirsMap,
        loaderButton,
        alert,
        errorMssge,
        severityAlert,
        sepomex,
        handleSubmit,
        error,
        changeNir,
        modalOpen,
        setOpenModal,
    };
};
