import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "moment/locale/es";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { LoadersContext } from "_models/context/app";

export const SalesDataTableHooks = ({
    getMySales,
    cleanMySalesAction,
    getMySalesDetails,
    cleanMySalesDetailsAction,
    mySales,
    mySalesDetails,
}) => {
    dayjs.locale("es");

    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    const currentDate = dayjs();

    const firstDayOfMonth = currentDate.startOf("month");
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState();
    const [options, setOptions] = useState([]);
    const [dropdownValue, setDropdownValue] = useState("");
    const [startValue, setStartValue] = useState(firstDayOfMonth);
    const [endValue, setEndValue] = useState(currentDate);
    const [fromTime, setFromTime] = useState(firstDayOfMonth.format());
    const [toTime, setToTime] = useState(currentDate.format());
    const [render, setRender] = useState(true);
    const [data, setData] = useState([]);
    const [displayDatepickers, setDisplayDatepickers] = useState(
        "displayDatepickers hidden"
    );
    const [dayDate, setDayDate] = useState("");
    const location = useLocation();
    const history = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const from_time_1 = queryParameters.get("from_time");
    const to_time_1 = queryParameters.get("to_time");
    const daySelected = queryParameters.get("date");

    useEffect(() => {
        if (from_time_1 !== null) {
            const fromDate = dayjs(from_time_1);
            const toDate = dayjs(to_time_1);

            const areNotFirstAndLastDay =
                !fromDate.isSame(firstDayOfMonth, "day") ||
                !toDate.isSame(currentDate.endOf("month"), "day");

            setFromTime(from_time_1);
            setToTime(to_time_1);

            if (areNotFirstAndLastDay) {
                setDisplayDatepickers("displayDatepickers shown");
                setStartValue(fromDate);
                setEndValue(toDate);
            } else {
                setDisplayDatepickers("displayDatepickers hidden");
            }
        } else if (daySelected !== null) {
        }
    }, [location, from_time_1, to_time_1, daySelected]);

    useEffect(() => {
        populateDropdown();
        const urlContent = window.location.href;

        if (urlContent.includes("from_time")) {
            const urlSplit = urlContent.split("?")[1];
            setFromTime(urlSplit.split("&")[0].split("from_time=")[1]);
            setToTime(urlSplit.split("&")[1].split("to_time=")[1]);
            setStartValue(dayjs(urlSplit.split("&")[0].split("from_time=")[1]));
            setEndValue(dayjs(urlSplit.split("&")[1].split("to_time=")[1]));
        }

        return () => {
            cleanMySalesAction();
        };
    }, []);

    useEffect(() => {
        if (location?.search == "" && !render) {
            if (
                fromTime.split("-")[2] !== "01T00:00:00" ||
                toTime.split("-")[2].split("T")[1] !== "23:59:59"
            ) {
                setDisplayDatepickers("displayDatepickers shown");
            }
            if (dropdownValue === 1) {
                getMySalesMethod();
            }
        }
    }, [fromTime, toTime]);

    useEffect(() => {
        if (fromTime && toTime && render) {
            setRender(false);
        }
    }, []);

    useEffect(() => {
        if (dropdownValue !== null && dropdownValue !== "" && !render) {
            setRender(false);
            if (dropdownValue !== 1) {
                getMySalesMethod();
            }
        }
    }, [dropdownValue]);

    useEffect(() => {
        const dataArray = [];
        dataArray.push(["Fecha", "# Venta diaria"]);
        const { payload } = mySales || {};
        const { result } = payload || {};
        if (mySales?.loaded) {
            setIsLoading(false);
            setRows(payload);
            if (result?.length > 0) {
                result?.map((item, index) => {
                    dataArray.push([item?.date || 0, item?.total_sales]);
                });
            }
        }
        setData(dataArray);
    }, [mySales]);

    useEffect(() => {
        if (dayDate !== "") {
            getMySalesDetails({
                date: dayDate,
            });
            history(`/sales/dashboard/details?date=${dayDate}`);
        }
    }, [dayDate]);

    const getMySalesMethod = () => {
        const fromTimeFormatted = fromTime
            ? dayjs(fromTime).format("YYYY-MM-DD")
            : "";
        const toTimeFormatted = toTime
            ? dayjs(toTime).format("YYYY-MM-DD")
            : "";

        const queryParams = `from_time=${fromTimeFormatted}&to_time=${toTimeFormatted}`;

        const newUrl = `/sales/dashboard?${queryParams}`;
        window.history.pushState(null, "", newUrl);

        getMySales({
            from_time: fromTimeFormatted,
            to_time: toTimeFormatted,
        });
    };

    const DialogComponent = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));

    const DialogTitleComponent = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    const handleClose = () => {
        setOpen(false);
        setIsScreenLoading(false);
    };

    const handleMySalesDetails = (param) => {
        // window.history.pushState(
        //     null,
        //     "",
        //     `/sales/dashboard/details?date=${param}`
        // );

        setDayDate(param);
    };

    const PopModalError = () => {
        return (
            <div>
                <DialogComponent
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitleComponent
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Error
                    </DialogTitleComponent>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <h1>Oops algo salió mal....</h1>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </DialogComponent>
            </div>
        );
    };

    const populateDropdown = () => {
        let myArray = [];

        for (let i = 0; i <= 5; i++) {
            const forPreviousID = moment()
                .subtract(i, "months")
                .format("YYYY-MM");

            const forPreviousName = moment()
                .subtract(i, "months")
                .format("MMMM");

            const forPreviousMonth = moment()
                .subtract(i, "months")
                .format("MM");

            const forPreviousYear = moment()
                .subtract(i, "months")
                .format("YYYY");

            myArray.push({
                id: forPreviousID,
                name: forPreviousName,
                month: forPreviousMonth,
                year: forPreviousYear,
            });
        }

        const urlContent = window.location.href;

        if (urlContent.includes("?")) {
            const urlSplit = urlContent.split("?")[1];
            const queryParams = urlSplit.split("&");

            let urlMonth = null;

            queryParams.forEach((param) => {
                const [key, value] = param.split("=");
                if (key === "from_time") {
                    urlMonth = moment(value).format("YYYY-MM");
                }
            });

            const urlMonthExistsInArray = myArray.some(
                (option) => option.id === urlMonth
            );

            if (urlMonthExistsInArray) {
                setDropdownValue(urlMonth);
            } else {
                setDropdownValue("Personalizado");
            }
        } else {
            setDropdownValue(moment().format("YYYY-MM"));
        }

        setOptions(myArray);
    };

    const inputChange = ({ target }) => {
        const { value, name } = target || {};

        if (value === 1) {
            setDisplayDatepickers("displayDatepickers shown");
            setStartValue(dayjs(null));
            setEndValue(dayjs(null));
        } else if (value === 0) {
            setDisplayDatepickers("displayDatepickers hidden");
            setFromTime(moment().startOf("month").format());
            setToTime(moment().add(1, "months").startOf("month").format());

            setStartValue(
                dayjs(moment().startOf("month").format("MM-DD-YYYY"))
            );
            setEndValue(dayjs(moment().endOf("month").format("MM-DD-YYYY")));
        } else {
            setDisplayDatepickers("displayDatepickers hidden");
            setFromTime(
                moment(value + "-01")
                    .startOf("month")
                    .format()
            );
            setToTime(
                moment(value)
                    // .add(1, "months")
                    .endOf("month")
                    .format()
            );

            setStartValue(
                dayjs(
                    moment(value + "-01")
                        .startOf("month")
                        .format("MM-DD-YYYY")
                )
            );
            setEndValue(
                dayjs(
                    moment(value + "-01")
                        .endOf("month")
                        .format("MM-DD-YYYY")
                )
            );
        }

        setDropdownValue(value);
    };

    const optionsGrid = {
        height: 500,
        annotations: { style: "point" },
        legend: { position: "bottom" },
        hAxis: { textPosition: "out" },
    };

    return {
        optionsGrid,
        isLoading,
        rows,
        options,
        dropdownValue,
        handleClose,
        data,
        inputChange,
        handleMySalesDetails,
        displayDatepickers,
        startValue,
        setStartValue,
        setDropdownValue,
        setFromTime,

        endValue,
        setEndValue,
        setToTime,
    };
};
