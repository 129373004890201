import { TextField, Grid, Typography, Button } from "@mui/material";
import DataTable from "widgets/datagrid/DataTable";
import { ContentRechargeHistoryHooks } from "../hooks/contentRechargeHistoryHooks";
import CustomDialog from "widgets/component/CustomDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { CircularProgressStyle } from "modules/transactions-netpay/list-transactions-netpay/style/style";

const ContentRechargeHistory = ({
    ticketId,
    rechargeHistoryAction,
    rechargeHistory,
    rechargeHistoryClean,
}) => {
    const {
        pagination,
        Columns,
        onSubmit,
        mappedRows,
        register,
        handleSubmit,
        size,
        errors,
        page,
        isDialogOpenResponse,
        dialogMessage,
        loading,
        handleClose,
    } = ContentRechargeHistoryHooks({
        ticketId,
        rechargeHistoryAction,
        rechargeHistory,
        rechargeHistoryClean,
    });

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} marginTop={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="from-date"
                            label="Fecha Desde"
                            type="date"
                            fullWidth
                            {...register("from_date", { required: true })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {errors.from_date && (
                            <span style={{ color: "red" }}>Es obligatorio</span>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="to-date"
                            label="Fecha Hasta"
                            type="date"
                            fullWidth
                            {...register("to_date", { required: true })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {errors.to_date && (
                            <span style={{ color: "red" }}>Es obligatorio</span>
                        )}
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px" }}
                >
                    Generar Reporte
                </Button>
            </form>
            <Typography
                variant="h6"
                style={{ marginTop: "20px", textAlign: "center" }}
            >
                Resultados del Reporte
            </Typography>
            <div style={{ height: 400, width: "100%", marginTop: "10px" }}>
                {loading ? (
                    <CircularProgress
                        style={CircularProgressStyle.CircularProgress}
                    />
                ) : (
                    <>
                        {mappedRows?.length > 0 ? (
                            <DataTable
                                rows={mappedRows}
                                columns={Columns}
                                page={page}
                                pageSize={size}
                                rowCount={pagination?.total_rows ?? 0}
                                onPageChange={pagination?.handleChangePage}
                                handleChangePageSize={
                                    pagination?.handleChangePageSize
                                }
                            />
                        ) : (
                            <Typography
                                variant="h6"
                                style={{ textAlign: "center" }}
                            >
                                No hay registros disponibles
                            </Typography>
                        )}
                    </>
                )}

                <CustomDialog
                    title="Cambio de Usuario"
                    content={dialogMessage}
                    onClose={handleClose}
                    open={isDialogOpenResponse}
                    fullWidth
                    maxWidth="xs"
                    showCloseButton={false}
                    extraButtons={[
                        {
                            label: "Aceptar",
                            variant: "contained",
                            onClick: handleClose,
                            buttonColor: "#724ECB",
                            textColor: "white",
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default ContentRechargeHistory;
