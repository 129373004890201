import { createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import Chip from "@mui/material/Chip";

const theme = createTheme({
    palette: {
        primary: {
            main: red[400],
        },
        secondary: {
            main: orange[700],
        },
        text: {
            default: "#000000",
        },
    },
});

export const renderPrevencionCell = (row) => {
    const { is_chargeback, is_fraud } = row || {};
    let bg = "default";
    let color = "#000";
    let label = "N/A";

    if (is_fraud && is_chargeback) {
        bg = theme.palette.primary.main;
        color = "#FFF";
        label = "Fraude, Contracargo";
    } else if (is_fraud) {
        bg = theme.palette.primary.main;
        color = "#FFF";
        label = "Fraude";
    } else if (is_chargeback) {
        bg = theme.palette.secondary.main;
        color = "#FFF";
        label = "Contracargo";
    }

    return <Chip label={label} style={{ backgroundColor: bg, color }} />;
};
