import React, { useEffect, useState, useContext } from "react";
import { SessionContext } from "_models/context/app";

export const ContainerModulesHook = (props) => {
    const sessionContext = useContext(SessionContext);
    const { typeServiceSelect, setTypeServiceSelect } = sessionContext || {};
    const [services, setServices] = useState([]);
    const [unitTypeService, setUnitTypeService] = useState("");

    useEffect(() => {
        if (typeServiceSelect == "calls") {
            setUnitTypeService("minutos");
        } else if (typeServiceSelect == "data") {
            setUnitTypeService("MB");
        } else if (typeServiceSelect == "sms") {
            setUnitTypeService("sms");
        }
    }, [typeServiceSelect]);

    useEffect(() => {
        const { customerProfile } = props.customerProfile || undefined;
        const offers = customerProfile?.offers || undefined;

        if (typeof offers != "undefined") {
            setServices(offers);
        }
    }, [props.customerProfile]);

    return {
        services,
        unitTypeService,
    };
};
