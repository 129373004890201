import React, { useContext, useEffect, useState } from "react";
import { LoadersContext } from "_models/context/app";
import { useParams } from "react-router-dom";

export const ActionBarHook = (props) => {
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext;

    const { changeUser_ } = props;
    const [modalOpenSim, setModalOpenSim] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [imeiNumber, setImeiNumber] = useState("");
    const [valueAgent, setValueAgent] = useState();
    const [agentList, setAgentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ticketId, setTicketId] = useState();
    const [directNumber, setDirectNumber] = useState("");
    const [loader, setLoader] = useState(false);
    const [statusProfile, setStatusProfile] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogOpenRechargeHistory, setisDialogOpenRechargeHistory] =
        useState(false);

    let params = useParams();

    const handleChange = async (event) => {
        setValueAgent(event.target.value);
        setIsScreenLoading(true);

        try {
            const response = await props.changeUser({
                ticketId: ticketId,
                user_id: event.target.value,
            });

            setIsScreenLoading(false);

            if (changeUser_?.response?.message === "success") {
                setDialogMessage("Usuario cambiado con éxito");
                setIsDialogOpen(true);
            } else if (changeUser_?.error?.response?.status > 300) {
                setDialogMessage(changeUser_?.error?.response?.data?.detail);
                setIsDialogOpen(true);
            }
        } catch (error) {
            setIsScreenLoading(false);

            console.error("Error en handleChange:", error);
            setDialogMessage("Ocurrió un error al cambiar el usuario.");
            setIsDialogOpen(true);
        } finally {
            setIsScreenLoading(false);
        }
    };

    useEffect(() => {
        setTicketId(params.ticketId);
        props.getUsers();
    }, []);

    useEffect(() => {
        const { events } = props.events || { events: undefined };

        if (Array.isArray(events.events)) {
            setEventsList(events.events);
            setLoader(false);
        }
    }, [props.events]);

    useEffect(() => {
        const { users } = props.userList.response || {
            users: undefined,
        };

        if (typeof users != "undefined") {
            setAgentList(users);
        }
    }, [props.userList]);

    useEffect(() => {
        const { customerProfile } = props.customerProfile || {
            customerProfile: undefined,
        };
        if (typeof customerProfile != "undefined") {
            setDirectNumber(customerProfile?.profile?.direct_number);
            setImeiNumber(customerProfile?.profile?.imei);
            setStatusProfile(customerProfile?.status?.subStatus);
        }
    }, [props.customerProfile]);

    useEffect(() => {
        setLoader(true);
    }, [props.changeUser_]);

    useEffect(() => {
        const response = props.ticketById.response || undefined;

        if (typeof response != "undefined") {
            setIsLoading(false);
            setValueAgent(response.assigned_to_user_id);
            setIsScreenLoading(false);
        }
    }, [props.ticketById]);

    return {
        handleChange,
        valueAgent,
        agentList,
        isLoading,
        loader,
        directNumber,
        imeiNumber,
        statusProfile,
        setDirectNumber,
        setImeiNumber,
        setStatusProfile,
        setisDialogOpenRechargeHistory,
        ticketId,
        setModalOpenSim,
        modalOpenSim,
        isDialogOpenRechargeHistory,
        dialogMessage,
        setIsDialogOpen,
        isDialogOpen,
    };
};
