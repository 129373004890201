import React from "react";
import {
    TextField,
    MenuItem,
    Grid,
    Select,
    InputLabel,
    FormControl,
} from "@mui/material";
import GenericAddButton from "widgets/GenericAddButton/GenericAddButton";
import SearchIcon from "@mui/icons-material/Search";

const FiltroNetPay = ({
    handleSubmit,
    onSubmit,
    register,
    errors,
    responseTransactionType,
}) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} xl={1}>
                    <TextField
                        fullWidth
                        label="*Desde"
                        type="date"
                        {...register("from_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors.from_date}
                        helperText={errors.from_date?.message}
                    />
                </Grid>
                <Grid item xs={12} xl={1}>
                    <TextField
                        fullWidth
                        label="*Hasta"
                        type="date"
                        {...register("to_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors.to_date}
                        helperText={errors.to_date?.message}
                    />
                </Grid>
                <Grid item xs={12} xl={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Status
                        </InputLabel>
                        <Select
                            fullWidth
                            {...register("status_id")}
                            error={!!errors.status_id}
                            helperText={errors.status_id?.message}
                        >
                            <MenuItem value={1}>Solicitado</MenuItem>
                            <MenuItem value={2}>Éxito</MenuItem>
                            <MenuItem value={3}>Fallo</MenuItem>
                            <MenuItem value={4}>Revisión</MenuItem>
                            <MenuItem value={5}>Error</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} xl={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Tipo de transacción
                        </InputLabel>
                        <Select
                            fullWidth
                            {...register("payment_type_id")}
                            error={!!errors.payment_type_id}
                            helperText={errors.payment_type_id?.message}
                        >
                            {responseTransactionType?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} xl={2}>
                    <TextField
                        fullWidth
                        label="*Autorización"
                        {...register("authorization_code")}
                        error={!!errors.authorization_code}
                        helperText={errors.authorization_code?.message}
                    />
                </Grid>

                <Grid item xs={12} xl={2}>
                    <TextField
                        fullWidth
                        label="*Tarjeta"
                        {...register("masked_pan_termination")}
                        error={!!errors.masked_pan_termination}
                        helperText={errors.masked_pan_termination?.message}
                    />
                </Grid>
                <Grid item xs={12} xl={1}>
                    <TextField
                        fullWidth
                        label="Monto"
                        type="number"
                        {...register("amount")}
                        error={!!errors.amount}
                        helperText={errors.amount?.message}
                        onWheel={(e) => e.target.blur()}
                    />
                </Grid>

                <Grid item xs={12} xl={1}>
                    <GenericAddButton
                        buttonText="Buscar"
                        backgroundColor="#4C2484"
                        size="medium"
                        isSubmit
                        icon={<SearchIcon />}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default FiltroNetPay;
