import axiosTokenInstance from "services/api/backoffice";
import {
    TRANSACTION_TYPES,
    TRANSACTION_TYPES_ERROR,
} from "_models/redux/types";

export const ActiontransactionsType = (params) => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `api/reports/transactions-type`
        );

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || null,
            type: TRANSACTION_TYPES,
        });
    } catch (e) {
        dispatch({
            method: "get",
            payload: e || {},
            status: e?.response?.status || null,
            type: TRANSACTION_TYPES_ERROR,
        });
    }
};
