import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Grid,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Card,
    CardContent,
} from "@mui/material";
import { municipalityAction } from "_models/redux/sepomex-municipality/action";
import { neighborhoodAction } from "_models/redux/sepomex-neighborhood/action";
import { getOffers } from "_models/redux/offers/action";
import { TranferSaleHook } from "./hooks/tranferSaleHook";
import ClientForm from "./components/ClientForm";
import AddressForm from "./components/AddressForm";
import { transferSalesAction } from "_models/redux/transfer-sales/action";
import SimForm from "./components/SimForm";
import { offersForSimPurchaseAction } from "_models/redux/offers-for-sim-purchase/action";
import { transferSalesClean } from "_models/redux/transfer-sales/clean-action";
import {
    loaderContainerStyle,
    containerStyle,
} from "./style/PageTransferSaleStyle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { cardStyle } from "../detail-transfer-sales/style/style";

const PageTransferSale = (props) => {
    const {
        registerOptions,
        errors,
        nameShrink,
        contactPhoneShrink,
        emailShrink,
        zipcodeShrink,
        register,
        states,
        municipalities,
        neighborhoods,
        simCount,
        setSimCount,
        eSimCount,
        setESimCount,
        selectedValues,
        setSelectedValues,
        handleAddESim,
        handleRemoveESim,
        handleAddSim,
        handleRemoveSim,
        handleESimChange,
        handleSimChange,
        handleFieldChange,
        generateFields,
        inputChange,
        handleNeighborhoodChange,
        calculateTotal,
        formattedTotal,
        offersData,
        handleAddItem,
        hasSim,
        loading,
        setDialogOpen,
        messageModal,
        dialogOpen,
        isESimOrSimAdded,
        setIsESimOrSimAdded,
        showErrorMessage,
        handleEnviarClick,
        handleGoBack,
        handleAccept,
        referenceInput,
        setReference,
    } = TranferSaleHook({ ...props });

    return (
        <>
            <Grid style={containerStyle} xs={12} sm={12} md={12} lg={12}>
                {loading && (
                    <Grid style={loaderContainerStyle}>
                        <CircularProgress />
                    </Grid>
                )}
                {!loading && (
                    <>
                        <Grid container xs={12} sm={12} md={12} lg={12}>
                            <Grid item>
                                <IconButton onClick={handleGoBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography variant="h5">
                                    Agregar Venta de Transferencia
                                </Typography>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={7}>
                                    <Card variant="outlined" sx={cardStyle}>
                                        <CardContent>
                                            <SimForm
                                                handleAddItem={(type) => {
                                                    handleAddItem(type);
                                                    setIsESimOrSimAdded(true);
                                                }}
                                                offersData={offersData}
                                                setSimCount={setSimCount}
                                                simCount={simCount}
                                                eSimCount={eSimCount}
                                                setESimCount={setESimCount}
                                                selectedValues={selectedValues}
                                                setSelectedValues={
                                                    setSelectedValues
                                                }
                                                handleAddESim={handleAddESim}
                                                handleRemoveESim={
                                                    handleRemoveESim
                                                }
                                                handleAddSim={handleAddSim}
                                                handleRemoveSim={
                                                    handleRemoveSim
                                                }
                                                handleESimChange={
                                                    handleESimChange
                                                }
                                                handleSimChange={
                                                    handleSimChange
                                                }
                                                handleFieldChange={
                                                    handleFieldChange
                                                }
                                                generateFields={generateFields}
                                                calculateTotal={calculateTotal}
                                                totalCost={formattedTotal}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={5}>
                                    <Card variant="outlined" sx={cardStyle}>
                                        <CardContent>
                                            <ClientForm
                                                inputChange={inputChange}
                                                register={register}
                                                registerOptions={
                                                    registerOptions
                                                }
                                                errors={errors}
                                                nameShrink={nameShrink}
                                                contactPhoneShrink={
                                                    contactPhoneShrink
                                                }
                                                emailShrink={emailShrink}
                                            />
                                        </CardContent>
                                    </Card>
                                    {hasSim && (
                                        <Card
                                            variant="outlined"
                                            sx={cardStyle}
                                            style={{ marginTop: 20 }}
                                        >
                                            <CardContent>
                                                <AddressForm
                                                    inputChange={inputChange}
                                                    register={register}
                                                    registerOptions={
                                                        registerOptions
                                                    }
                                                    errors={errors}
                                                    states={states}
                                                    municipalities={
                                                        municipalities
                                                    }
                                                    neighborhoods={
                                                        neighborhoods
                                                    }
                                                    zipcodeShrink={
                                                        zipcodeShrink
                                                    }
                                                    handleNeighborhoodChange={
                                                        handleNeighborhoodChange
                                                    }
                                                    referenceInput={
                                                        referenceInput
                                                    }
                                                    setReference={setReference}
                                                />
                                            </CardContent>
                                        </Card>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent="center"
                                style={{ marginTop: 20 }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleEnviarClick}
                                >
                                    Enviar
                                </Button>
                            </Grid>
                            {showErrorMessage && !isESimOrSimAdded && (
                                <Typography
                                    variant="body2"
                                    color="error"
                                    style={{ marginLeft: 10 }}
                                >
                                    Es necesario agregar al menos una eSIM o SIM
                                </Typography>
                            )}
                            <Dialog
                                open={dialogOpen}
                                onClose={() => setDialogOpen(false)}
                            >
                                <DialogTitle>Mensaje</DialogTitle>
                                <DialogContent>
                                    <Typography variant="body1">
                                        {messageModal}
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            setDialogOpen(false);
                                            handleAccept();
                                        }}
                                        color="primary"
                                    >
                                        Aceptar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => ({
    municipality: state?.municipality,
    neighborhood: state?.neighborhood,
    offers: state?.offers,
    transferSales: state?.transferSales,
    ofersSimPurchase: state?.ofersSimPurchase,
});

const mapDispatchToProps = {
    municipalityAction,
    neighborhoodAction,
    getOffers,
    transferSalesAction,
    offersForSimPurchaseAction,
    transferSalesClean,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTransferSale);
