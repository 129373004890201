import React, { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";

export const ActionImeiHook = (props) => {
    const { directNumber, ticketId } = props;
    const { getActionImei } = props || {};
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpenDeviceInfo, setModalOpenDeviceInfo] = useState(false);
    const [infoDevicePopup, setInfoDevicePopup] = useState(false);
    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        const imeiObj = getActionImei || undefined;
        if (imeiObj.imeiObj.length != 0 && infoDevicePopup) {
            setIsLoading(false);
            setModalOpenDeviceInfo(true);
        }
    }, [getActionImei]);

    const handleActionInfo = () => {
        setIsLoading(true);
        setInfoDevicePopup(true);
        props.getImei({ ticket_id: ticketId });
    };

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    return {
        handleActionInfo,
        modalOpenDeviceInfo,
        setModalOpenDeviceInfo,
    };
};
