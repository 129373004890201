import React, { useState, useEffect, useContext } from "react";
import { LoadersContext } from "_models/context/app";
import { useParams } from "react-router-dom";

export const ActionSupplementaryServiceHook = (props) => {
    let params = useParams();
    const { directNumber, ticketId } = props;
    const [modalOpen, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hiddenComponent, setHiddenComponent] = useState("hidden-element");
    const [tripartiteCallWaiting, setTripartiteCallWaiting] = useState(false);
    const [showPrivateNumbers, setShowPrivateNumbers] = useState(false);
    const [typeCallForwarding, setTypeCallForwarding] = useState("none");
    const [msisdnCallForwarding, setMsisdnCallForwarding] = useState("");
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");
    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setIsLoading(false);

        const supplementaryService = props.supplementaryService || undefined;

        const supplementaryService_ =
            supplementaryService.supplementaryService || undefined;

        if (supplementaryService_.msisdn === directNumber) {
            setOpenModal(true);

            if (supplementaryService_.tripartiteCallWaiting === "SI") {
                setTripartiteCallWaiting(true);
            } else {
                setTripartiteCallWaiting(false);
            }
            if (supplementaryService_.showPrivateNumbers === "SI") {
                setShowPrivateNumbers(true);
            } else {
                setShowPrivateNumbers(false);
            }
            if (supplementaryService_.callForwarding === "SI") {
                setTypeCallForwarding("call-forwarding");
                setHiddenComponent("form-control");
                setMsisdnCallForwarding(
                    supplementaryService_.msisdn_callForwarding
                );
            } else if (supplementaryService_.voiceMail === "SI") {
                setTypeCallForwarding("voice-mail");
                setHiddenComponent("hidden-element");
                setMsisdnCallForwarding(undefined);
            } else if (
                supplementaryService_.unconditionalCallForwarding === "SI"
            ) {
                setTypeCallForwarding("unconditional-call-forwarding");
                setHiddenComponent("form-control");
                setMsisdnCallForwarding(
                    supplementaryService_.msisdn_unconditionalCallForwarding
                );
            }
        }
    }, [props.supplementaryService]);

    useEffect(() => {
        setIsLoadingButton(false);

        const changeSupplementaryService =
            props.changeSupplementaryService || {};

        const changeSuppServ =
            changeSupplementaryService.changeSupplementaryService || {};

        if (changeSuppServ.status == "success") {
            setSeverityAlert("success");
            setErrorMssge("El cambió se realizó satisfactoriamente");
            setAlert(true);
            props.getEvents({ ticket_id: params.ticketId });
        } else {
            setErrorMssge("Error");
            setSeverityAlert("error");
            setAlert(true);
        }
    }, [props.changeSupplementaryService]);

    const handleClickButton = () => {
        setIsLoading(true);
        props.getSupplementaryService({ ticket_id: ticketId });
    };

    const handleCloseClean = () => {
        setOpenModal(false);
        setAlert(false);
        setMsisdnCallForwarding("");
        props.cleanSupplementaryService();
    };

    const handleChangeCallForwarding = (event) => {
        setAlert(false);
        setTypeCallForwarding(event.target.value);
        if (
            event.target.value == "call-forwarding" ||
            event.target.value == "unconditional-call-forwarding"
        ) {
            setHiddenComponent("form-control");
        } else {
            setHiddenComponent("hidden-element");
        }
        if (
            event.target.value == "none" ||
            event.target.value == "voice-mail"
        ) {
            setMsisdnCallForwarding(undefined);
        }
    };

    const handleChangetripartiteCallWaiting = (event) => {
        setAlert(false);
        setTripartiteCallWaiting(event.target.checked);
    };

    const handleChangeShowPrivateNumbers = (event) => {
        setAlert(false);
        setShowPrivateNumbers(event.target.checked);
    };

    const handleChangeNumberCallForwarding = ({ target }) => {
        const { value } = target;
        setMsisdnCallForwarding(value);
    };

    const handleSubmitChangeSupplementaryService = () => {
        setIsLoadingButton(true);
        let service_direct_number = msisdnCallForwarding;
        if (msisdnCallForwarding == undefined) {
            service_direct_number = undefined;
        } else {
            service_direct_number = service_direct_number.toString();
        }

        props.postSupplementaryService({
            ticket_id: ticketId,
            service: typeCallForwarding,
            service_direct_number: service_direct_number,
            tripartite_call_waiting: tripartiteCallWaiting,
            show_private_numbers: showPrivateNumbers,
        });
    };

    return {
        modalOpen,
        hiddenComponent,
        tripartiteCallWaiting,
        showPrivateNumbers,
        typeCallForwarding,
        msisdnCallForwarding,
        isLoadingButton,
        alert,
        errorMssge,
        severityAlert,
        handleClickButton,
        handleCloseClean,
        handleChangeCallForwarding,
        handleChangetripartiteCallWaiting,
        handleChangeShowPrivateNumbers,
        handleChangeNumberCallForwarding,
        handleSubmitChangeSupplementaryService,
    };
};
